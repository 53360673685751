import DropDown from 'components/company/DropDown';
import CreatePcr from 'components/dashboard/CreatePcr';
import { DropDownCategory } from 'components/dashboard/DropDownCategory';
import { ProcessStatus } from 'constants/constants';
import controlIcon from 'images/icons/svg/controls.svg';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { PCRModel, PCRStatus, ProductCategoryModel } from 'services/EpdClient';
import PcrService from 'services/PcrService';
import styled from 'styled-components';
import { A, Aside, Container, H2, MainView, Text } from 'styles/Styles.styled';

import DetailsPcr from './DetailsPcr';

const PcrDashboard: React.FunctionComponent = () => {
  const [allPcrs, setAllPcrs] = useState<PCRModel[]>([]);
  const [categories, setCategories] = useState<ProductCategoryModel[]>([]);
  const [status, setStatus] = useState<ProcessStatus>(ProcessStatus.None);
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(undefined);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [newId, setNewId] = useState<any>();

  let allStatus: string[] = Object.values(PCRStatus);
  allStatus.shift();
  const [checkedState, setCheckedState] = useState<any>({
    [allStatus[0]]: {
      isChecked: false,
    },
    [allStatus[1]]: {
      isChecked: false,
    },
    [allStatus[2]]: {
      isChecked: false,
    },
  });

  const handleOnChange = (x: string) => {
    const newCheckedState = { ...checkedState, [x]: { isChecked: !checkedState[x].isChecked } };
    setCheckedState(newCheckedState);
  };

  const onChangeCategory = (categoryId?: string) => {
    setSelectedCategory(categoryId);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setStatus(ProcessStatus.Fetching);
        const categoriesResult = await PcrService.getAllCategories();
        setCategories(categoriesResult);
        setStatus(ProcessStatus.Success);
      } catch (error) {
        setStatus(ProcessStatus.Error);
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchPcrs = async () => {
      try {
        setStatus(ProcessStatus.Fetching);
        const pcrs = await PcrService.getPcrs(undefined, undefined, undefined, selectedCategory);
        let checkedKeys: any[] = [];
        for (let key in checkedState) {
          if (checkedState[key].isChecked) {
            checkedKeys.push(key);
          }
        }
        if (checkedKeys.length === 0) {
          setAllPcrs(pcrs);
          setStatus(ProcessStatus.Success);
          return;
        } else {
          const result = pcrs.filter((item) => checkedKeys.includes(item.status));
          setAllPcrs(result);
          setStatus(ProcessStatus.Success);
          return;
        }
      } catch (error) {
        setStatus(ProcessStatus.Error);
      }
    };
    fetchPcrs();
  }, [checkedState, selectedCategory]);

  const onClickCard = (pcrId: any) => {
    setIsSelected(true);
    setNewId(pcrId);
  };

  const onCloseDialog = () => {
    setIsSelected(false);
  };
  return (
    <Container style={{ flexWrap: 'nowrap', position: 'relative' }}>
      <MainView>
        <HeadingArea>
          <H2>PCR Dashboard </H2>
          <FilterContainer>
            {categories && (
              <div>
                <DropDownCategory categories={categories} onChangeCategory={onChangeCategory} />
              </div>
            )}

            <div style={{ position: 'relative' }}>
              {/* <img alt="Controls" src={controlIcon} style={{ height: '1.25rem', marginRight: '1rem' }} /> */}
              <DropDown icon={controlIcon} heading={'Status'} content={Tooltips.PCRStatus}>
                <div style={{ padding: '1rem 0' }}>
                  {allStatus.map((x: string) => (
                    <InputLabel>
                      {x}
                      <input
                        type="checkbox"
                        value={x}
                        checked={checkedState[x].isChecked}
                        onChange={() => handleOnChange(x as string)}
                      />
                    </InputLabel>
                  ))}
                </div>
              </DropDown>
            </div>
            <CreatePcr goToEditPcrPage={false} />
          </FilterContainer>
        </HeadingArea>

        <CardContainer>
          {status === ProcessStatus.Fetching && <div>Fetching PCRs...</div>}
          {status === ProcessStatus.Error && (
            <CardsEmpty>
              <h3>Something went wrong</h3>
            </CardsEmpty>
          )}

          {!isSelected ? (
            status === ProcessStatus.Success &&
            (!isEmpty(allPcrs) ? (
              allPcrs.map((pcr, i) => (
                <Card onClick={() => onClickCard(pcr.id)} key={pcr.id}>
                  <CardHeading>{pcr.registrationNumber}</CardHeading>
                  {pcr.name}
                  <CardStatus type={pcr.status}>
                    <span>{pcr.status}</span>
                  </CardStatus>
                </Card>
              ))
            ) : (
              <CardsEmpty>
                <h3>No PCRs to list</h3>
                {allPcrs.length > 0 ? <div>Try changing the filter.</div> : <div>There are no PCRs for this user.</div>}
              </CardsEmpty>
            ))
          ) : (
            <DetailsPcr pcrId={newId} onClose={onCloseDialog} />
          )}
        </CardContainer>
      </MainView>
      <Aside>
        {allPcrs.length > 0 && (
          <>
            <H2>PCR Quick Stats</H2>
            <Text style={{ marginBottom: '3rem' }}>
              <QuickFactBox>
                <span>Registered:</span>
                <strong> {allPcrs.filter((x) => x.status === PCRStatus.Registered)?.length}</strong>

                <span>De-registered:</span>
                <strong> {allPcrs.filter((x) => x.status === PCRStatus.Deregistered)?.length}</strong>

                <span>Under development:</span>
                <strong> {allPcrs.filter((x) => x.status === PCRStatus.UnderDevelopment)?.length}</strong>
              </QuickFactBox>
            </Text>
          </>
        )}

        <H2>FAQ</H2>
        <Text>
          <strong>How to publish a PCR?</strong>
          <br />
          <A
            href="https://www.environdec.com/product-category-rules-pcr/develop-a-pcr"
            target="_blank"
            rel="noopener noreferrer"
          >
            Publish PCR
          </A>
          <br />
          <br />
          <strong>How to update a PCR?</strong>
          <br />
          <A
            href="https://environdec.com/product-category-rules-pcr/get-involved-in-pcr-development"
            target="_blank"
            rel="noopener noreferrer"
          >
            Update PCR
          </A>
          <br />
          <br />
          <strong>Read our GPI</strong>
          <br />
          <A
            href="https://www.environdec.com/resources/documentation#generalprogrammeinstructions"
            target="_blank"
            rel="noopener noreferrer"
          >
            GPI
          </A>
          <br />
          <br />
        </Text>
      </Aside>
    </Container>
  );
};
export default PcrDashboard;

const Tooltips = {
  switchAccount: 'Switch between your accounts',
  updateSettings: 'Update the settings of an organisation.',
  PCRStatus: 'Filter PCRs by status.',
};
export const InfoBox = styled.div`
  display: flex;
  padding: 1rem;
  border-left: solid 3px green;
  margin: 0.5rem 0;
  background-color: #f3f3f3;
  margin-right: 1vw;
`;

const QuickFactBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem;
  background-color: white;
  box-shadow: 5px 5px 5px ${(props) => props.theme.colors.darkGray};
  > span {
    flex: 0 1 60%;
    padding: 0.25rem;
  }
  > strong {
    flex: 0 1 30%;
    text-align: right;
    padding: 0.25rem;
  }
`;

const InputLabel = styled.label`
  margin: 0.5rem 1.5rem;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
`;

const CardsEmpty = styled.div`
  flex: 1 0 auto;
  text-align: center;
  margin: 2rem;
`;

const CardStatus = styled.div<{ type?: PCRStatus }>`
  ${(props) => props.theme.fonts.textSmall};
  font-style: italic;
  flex: 1 0 auto;
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  color: white;
  font-weight: normal;
  position: absolute;
  bottom: 1rem;
  right: 1rem;

  > span {
    background-color: ${(props) =>
      props.type === PCRStatus.Registered ? props.theme.colors.regionColorLight : props.theme.colors.darkGray};
    /* border-radius: 0.5rem; */
    padding: 0.25rem 0.75rem;
  }
`;

const CardHeading = styled.h4`
  margin: 0;
  ${(props) => props.theme.fonts.textSmall};
  color: ${(props) => props.theme.colors.gray};
`;

const Card = styled.div<{ background?: string }>`
  flex: 0 0 23%;
  height: 150px;
  position: relative;
  overflow: hidden;
  border: solid 1px ${(props) => props.theme.colors.gray};
  border-radius: 4px;
  margin-bottom: 1.5rem;
  box-sizing: border-box;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11), 0 4px 4px rgba(0, 0, 0, 0.11);
  transition: transform 0.15s;
  margin: 1%;
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(0.375turn, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)),
    url(${(props) => props.background});
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1rem;
  font-weight: bold;

  &:hover {
    transform: scale(1.05);
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  & ${Card}:nth-child(4n+1) {
    margin-left: 0;
  }
`;

const HeadingArea = styled.div`
  display: flex;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  justify-content: flex-end;
  margin-right: 1.5rem;
`;
