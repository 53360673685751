import { useEpdLcaCustomResultGridData } from 'services/api/queries';

import {
  createVariationColumnWithDisclaimer,
  createVariationDisclaimerNote,
  LcaResultsAdditionalGridColumns,
  LcaResultsGridColumnsPdf,
} from '../LcaResultsGridColumns';
import LcaResultsGridPdf from '../LcaResultsGridPdf';
import LcaCustomResultsDynamicGrid from './LcaCustomResultsDynamicGrid';
import { LcaCustomResultModel } from 'types/types';

type TProps = {
  epdVersionId: string;
  customResult: LcaCustomResultModel;
  isForPdf?: boolean;
  disabled?: boolean;
  preferEnabledAppearanceEvenWhenDisabled?: boolean;
  isSingleProductSelected: boolean;
  onChangedCustomResult?: (propertyName: string, val: any) => void;
};

const LcaCustomAdditionalResultsGrid = ({
  epdVersionId,
  customResult,
  isForPdf,
  disabled,
  preferEnabledAppearanceEvenWhenDisabled,
  isSingleProductSelected,
  onChangedCustomResult,
}: TProps) => {
  const rows = useEpdLcaCustomResultGridData(customResult.id).data;

  const notes: { note: React.ReactNode; description: React.ReactNode }[] = [];
  let columns = isForPdf ? LcaResultsGridColumnsPdf : LcaResultsAdditionalGridColumns;
  if (!isSingleProductSelected && (!isForPdf || (isForPdf && rows?.some((r) => r.variation)))) {
    const disclaimerNumber = 1;
    columns = [...columns, createVariationColumnWithDisclaimer(disclaimerNumber)];
    notes.push(createVariationDisclaimerNote(disclaimerNumber));
  }

  const editableFields: { fieldName: string; label: React.ReactNode; value: string; placeholder: string }[] = [
    {
      fieldName: 'gridAcronyms',
      label: 'Acronyms',
      value: customResult.gridAcronyms,
      placeholder: 'Acronym¹ description; Acronym² - description; etc.',
    },
    {
      fieldName: 'gridDisclaimers',
      label: <span>Disclaimers</span>,
      value: customResult.gridDisclaimers,
      placeholder:
        notes.length === 0
          ? 'Disclaimer¹ description; Disclaimer² - description; etc.'
          : 'Disclaimer² description; Disclaimer³ - description; etc.',
    },
  ];

  if (isForPdf) {
    return (
      <LcaResultsGridPdf rows={rows} columns={columns} notes={notes} editableFields={editableFields} />
    );
  }

  return (
    <LcaCustomResultsDynamicGrid
      title="Additional environmental performance results"
      epdVersionId={epdVersionId}
      customResultId={customResult.id}
      rows={rows}
      columns={columns}
      editableFields={editableFields}
      notes={notes}
      onChangedCustomResult={onChangedCustomResult}
      disabled={disabled}
      preferEnabledAppearanceEvenWhenDisabled={preferEnabledAppearanceEvenWhenDisabled}
    />
  );
};

export default LcaCustomAdditionalResultsGrid;
