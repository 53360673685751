import menuIcon from 'assets/images/icons/button/menu.svg';
import pdfDownloadIcon from 'assets/images/icons/button/pdf-download.svg';
import ErrorFallback from 'components/error-fallback/ErrorFallback';
import OverlaySpinner from 'components/spinners/OverlaySpinner';
import TitleAndBack from 'components/v2/TitleAndBack';
import { ActionImageGreenButton, ActionPrimaryButton, CompilerActionSecondaryButton } from 'components/v2/buttons';
import StepPanel from 'components/v2/compiler/StepPanel';
import useCompilerLogic from 'components/v2/compiler/useCompilerLogic';
import { confirmAlert } from 'components/v2/confirm-dialog/ConfirmAlert';
import { confirmDelete } from 'components/v2/confirm-dialog/ConfirmDelete';
import { confirmQuestion } from 'components/v2/confirm-dialog/ConfirmQuestion';
import { ConfirmWithCommentPayload } from 'components/v2/dialogs/ConfirmWithCommentDialog';
import DeclinePublicationDialog from 'components/v2/dialogs/DeclinePublicationDialog';
import DeclineVerificationDialog from 'components/v2/dialogs/DeclineVerificationDialog';
import ConfirmEditorialSendToPublishDialog from 'components/v2/dialogs/ConfirmEditorialSendToPublishDialog';
import ConfirmEditorialSendToPublishProcessCertificationDialog from 'components/v2/dialogs/ConfirmEditorialSendToPublishProcessCertificationDialog';
import DepublishAdminDialog from 'components/v2/dialogs/DepublishAdminDialog';
import { HelpBox, HelpBoxHeader } from 'components/v2/help-boxes';
import { toaster } from 'components/v2/toast';
import { ProcessStatus } from 'constants/constants';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { RadioButtonChangeEvent } from 'primereact/radiobutton';
import React, { ReactNode, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { EpdLinks } from 'routes/EpdRoutes';
import { ActionState } from 'services/EpdClient';
import EpdStateService from 'services/EpdStateService';
import {
  useApproveVerification,
  useCancelApproveVerificationMutation,
  useCancelEpdDeregistration,
  useCancelRegistrationEpd,
  useCancelUpdateEpd,
  useCancelVerification,
  useDeclinePublicationEpd,
  useDeclineVerification,
  useDepublishEpd,
  useDeregisterEpd,
  useEditorialUpdating,
  useLicenseeAdminVerificationApprove,
  useLicenseeAdminVerificationDecline,
  usePublishEpd,
  useRegisterEditorial,
  useRegisterEpd,
  useStartVerification,
  useUnlockEpd,
} from 'services/api/mutations';
import { useEPDDefinitionInformation } from 'services/api/queries';
import styled from 'styled-components';
import { Container } from 'styles/Styles.styled';
import { formatUtcDateWithOffset } from 'util/utils';

import StatusBox from '../../../components/v2/compiler/StatusBox';
import {
  CheckBoxPanel,
  ErrorTextBox,
  RadioButtonStyled,
  WizardHeaderButtonBox,
  WizardHeaderGrid,
} from '../../../styles/v2/Styles.styled';
import { withGreyBackground } from '../WithGreyBackground';

type VisibleDialogType =
  | 'unlock'
  | 'declineVerification'
  | 'confirmEditorialSendToPublish'
  | 'declinePublication'
  | 'declinePublicationLicensee'
  | 'depublishByAdmin';

const EpdCompiler: React.FunctionComponent = () => {
  const actionMenuRef = useRef<Menu>(null);
  const [status, setStatus] = useState<ProcessStatus>(ProcessStatus.None);
  const { t } = useTranslation();
  const { epdId, epdVersionId, history, redirect, stepId, steps, compilerCompositeStatus } = useCompilerLogic();
  const actionsStates = compilerCompositeStatus?.actionsStatus;

  const epdDefinition = useEPDDefinitionInformation(epdVersionId!).data;

  const cancelUpdateEpdMutation = useCancelUpdateEpd(epdId, epdVersionId!);
  const startVerificationMutation = useStartVerification(epdId, epdVersionId!);
  const cancelVerificationMutation = useCancelVerification(epdId, epdVersionId!);
  const registerEpdMutation = useRegisterEpd(epdId, epdVersionId!);
  const cancelRegistrationEpdMutation = useCancelRegistrationEpd(epdId, epdVersionId!);
  const deregisterEpdMutation = useDeregisterEpd(epdId, epdVersionId!);
  const cancelEpdDeregistrationMutation = useCancelEpdDeregistration(epdId, epdVersionId!);
  const unlockEpdMutation = useUnlockEpd(epdId, epdVersionId!);
  const approveVerificationMutation = useApproveVerification(epdId, epdVersionId!);
  const declineVerificationMutation = useDeclineVerification(epdId, epdVersionId!);
  const cancelApproveVerificationMutation = useCancelApproveVerificationMutation(epdId, epdVersionId!);
  const licenseeAdminVerificationApproveMutation = useLicenseeAdminVerificationApprove(epdId, epdVersionId!);
  const publishEpdMutation = usePublishEpd(epdId, epdVersionId!);
  const declinePublicationMutation = useDeclinePublicationEpd(epdId, epdVersionId!);
  const depublishEpdMutation = useDepublishEpd(epdId, epdVersionId!);
  const licenseeAdminVerificationDeclineMutation = useLicenseeAdminVerificationDecline(epdId, epdVersionId!);
  const registerEditorialMutation = useRegisterEditorial(epdId, epdVersionId!);
  const editorialUpdatingMutation = useEditorialUpdating(epdId, epdVersionId!);

  const [visibleDialog, setVisibleDialog] = useState<VisibleDialogType | null>(null);
  const [epdUpdateType, setEpdUpdateType] = useState<'update' | 'editorialUpdate'>('update');

  const onGoBack = () => {
    confirmQuestion({
      caption: 'Do you want to leave the editing process?',
      description: (
        <div>
          All entered data will be saved.
          <p style={{ marginTop: '0.5rem' }}>You can access your EPD from the dashboard.</p>
        </div>
      ),
      acceptLabel: 'Leave',
      rejectLabel: 'Cancel',
      onConfirm: () => {
        history.push(EpdLinks.dashboard());
      },
      closable: false,
      contentStyle: { paddingTop: '2.5rem' },
    });
  };

  const onAcceptCancelUpdateEpd = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await cancelUpdateEpdMutation.mutateAsync();
      setStatus(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.epdCancelled') as string,
      });
      location.reload();
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'EPD update cancellation has not been accomplished due to unexpected error.',
      });
    }
  };

  const onCancelUpdateEpd = () =>
    confirmAlert({
      caption: `Are you sure you want to cancel the ${epdDefinition?.fullIdentificationNumber} ${epdDefinition?.declarationName} update?`,
      description: <div>All updated changes will be lost.</div>,
      acceptLabel: t('Submit'),
      rejectLabel: t('Cancel'),
      onConfirm: onAcceptCancelUpdateEpd,
    });

  const onAcceptDeleteEpd = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await EpdStateService.delete(epdId);
      setStatus(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.epdDeleted', {
          epdNumber: epdDefinition?.fullIdentificationNumber,
        }) as string,
      });
      history.push(EpdLinks.dashboard());
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({ severity: 'error', summary: 'Error', details: 'EPD has not been deleted due to unexpected error.' });
      console.error(error);
    }
  };

  const onDeleteEpd = () =>
    confirmDelete({
      caption: `Are you sure you want to delete the EPD "${epdDefinition?.fullIdentificationNumber} ${epdDefinition?.declarationName}"?`,
      description: <div>The EPD data will be lost and you will not be able to restore it.</div>,
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      onConfirm: onAcceptDeleteEpd,
    });

  const onStartVerification = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await startVerificationMutation.mutateAsync();
      setStatus(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.startVerification') as string,
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'EPD verification has not been started due to unexpected error.',
      });
    }
  };

  const onCancelVerification = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await cancelVerificationMutation.mutateAsync();
      setStatus(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.cancelVerification') as string,
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'EPD verification has not been canceled due to unexpected error.',
      });
    }
  };

  const onRegister = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await registerEpdMutation.mutateAsync();
      setStatus(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: (
          <div>
            <b>{'Thank you for registering your EPD with the International EPD System!'}</b>
            <p style={{ marginBottom: 0 }}>
              {
                'We will attend to it as soon as possible. We aim to publish the EPD within 1-3 days. If you have any questions feel free to contact us in the Secretariat at support@environdec.com.'
              }
            </p>
            <p style={{ marginBottom: 0 }}>
              {
                'If any information is missing or if any irregularities are recognized in the submitted material the support team will contact you.'
              }
            </p>
          </div>
        ) as ReactNode,
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'EPD has not been registered due to unexpected error.',
      });
    }
  };

  const onCancelRegistrationClicked = () => {
    if (!epdDefinition) {
      return;
    }
    confirmAlert({
      description: (
        <span style={{ whiteSpace: 'pre-wrap' }}>
          {t('confirmModal.cancelRegistrationEPD', {
            name: `${epdDefinition.fullIdentificationNumber} ${epdDefinition.declarationName}`,
          })}
        </span>
      ),
      acceptLabel: t('Submit'),
      onConfirm: onCancelRegistrationConfirmed,
    });
  };

  const onCancelRegistrationConfirmed = async () => {
    if (!epdDefinition) {
      return;
    }
    try {
      setStatus(ProcessStatus.Fetching);
      await cancelRegistrationEpdMutation.mutateAsync();
      setStatus(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.canceledRegistrationEpd', {
          name: `${epdDefinition.fullIdentificationNumber} ${epdDefinition.declarationName}`,
        }) as string,
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'EPD publication has not been canceled due to unexpected error.',
      });
    }
  };

  const onConfirmDeregisterEpd = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await deregisterEpdMutation.mutateAsync();
      setStatus(ProcessStatus.Success);
      history.go(0);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.epdDeregister', {
          epdNumber: epdDefinition?.fullIdentificationNumber,
        }) as string,
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'EPD has not been deregistered due to unexpected error.',
      });
    }
  };

  const onDeregisterEpd = () =>
    confirmDelete({
      caption: t('confirmModal.deregisterEPD'),
      acceptLabel: t('deregister'),
      rejectLabel: t('cancel'),
      onConfirm: onConfirmDeregisterEpd,
    });

  const onCancelEpdDeregistration = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await cancelEpdDeregistrationMutation.mutateAsync();
      setStatus(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.cancelDeregistration') as string,
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Cancelation of EPD deregistration has not been accomplished due to unexpected error.',
      });
    }
  };

  const onStartUpdatingEpd = async () => {
    if (!actionsStates) {
      return;
    }
    if (actionsStates.isVerifierInvolved) {
      setVisibleDialog('unlock');
      return;
    }

    startUpdatingHandler('editorialUpdate');
  };

  const startUpdatingHandler = async (updateType: 'update' | 'editorialUpdate') => {
    try {
      setStatus(ProcessStatus.Fetching);

      if (updateType === 'update') {
        await unlockEpdMutation.mutateAsync();
      } else if (updateType === 'editorialUpdate') {
        await editorialUpdatingMutation.mutateAsync({ epdId });
      }
      setStatus(ProcessStatus.Success);
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Updating of EPD has not been started due to unexpected error.',
      });
    }
  };

  const handleEditorialSendToPublishConfirmed = (payload: ConfirmWithCommentPayload | null) => {
    setVisibleDialog(null);
    if (!payload) {
      return;
    }
    registerEditorialHandler(payload);
  };

  const registerEditorialHandler = async (payload: ConfirmWithCommentPayload) => {
    try {
      setStatus(ProcessStatus.Fetching);
      await registerEditorialMutation.mutateAsync({ epdId, notes: payload.commentText });

      setStatus(ProcessStatus.Success);
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Updating of EPD has not been started due to unexpected error.',
      });
    }
  };

  const onApproveVerificationEpd = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      const approveWithoutEmailNotification = false;
      const currentDateTimeWithTimezone = formatUtcDateWithOffset(new Date());
      const approveModel = new FormData();
      approveModel.append('WithoutEmailNotification', approveWithoutEmailNotification.toString());
      approveModel.append('LastRevisionDate', currentDateTimeWithTimezone);
      await approveVerificationMutation.mutateAsync(approveModel);
      setStatus(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.approvedVerification', {
          epdNumber: epdDefinition?.fullIdentificationNumber,
        }) as string,
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Approval of EPD has not been accomplished due to unexpected error.',
      });
    }
  };

  const onDeclineVerificationEpd = async (payload: ConfirmWithCommentPayload) => {
    try {
      setStatus(ProcessStatus.Fetching);
      await declineVerificationMutation.mutateAsync({
        withoutEmailNotification: false,
        reason: payload.commentText,
      });
      setStatus(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.declinedVerification', {
          epdNumber: epdDefinition?.fullIdentificationNumber,
        }) as string,
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Rejection of EPD has not been accomplished due to unexpected error.',
      });
    }
  };

  const onRetractVerification = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await cancelApproveVerificationMutation.mutateAsync();
      setStatus(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.retractVerification') as string,
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Retraction of EPD verification has not been accomplished due to unexpected error.',
      });
    }
  };

  const onLicenseeAdminApproveEpd = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await licenseeAdminVerificationApproveMutation.mutateAsync();
      setStatus(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.approveEpdChanges') as string,
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Approval of EPD by licensee admin has not been accomplished due to unexpected error.',
      });
    }
  };

  const onPublishEpd = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await publishEpdMutation.mutateAsync(epdVersionId!);
      setStatus(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.epdPublished', {
          epdNumber: epdDefinition?.fullIdentificationNumber,
        }) as string,
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Publication of EPD has not been accomplished due to unexpected error.',
      });
    }
  };

  const onDeclinePublicationEpd = async (payload: ConfirmWithCommentPayload, actor: 'bySuperAdmin' | 'byLicensee') => {
    try {
      setStatus(ProcessStatus.Fetching);
      if (actor === 'bySuperAdmin') {
        await declinePublicationMutation.mutateAsync({
          declineReason: payload.commentText,
        });
      } else {
        await licenseeAdminVerificationDeclineMutation.mutateAsync({
          declineReason: payload.commentText,
        });
      }
      setStatus(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.declinedPublication', {
          epdNumber: epdDefinition?.fullIdentificationNumber,
        }) as string,
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Rejection of EPD has not been accomplished due to unexpected error.',
      });
    }
  };

  const onDepublishEpd = async (payload?: ConfirmWithCommentPayload) => {
    try {
      setStatus(ProcessStatus.Fetching);
      await depublishEpdMutation.mutateAsync({
        depublicationReason: payload ? payload.commentText : null,
      });
      setStatus(ProcessStatus.Success);
      toaster({
        severity: 'success',
        summary: 'Success',
        details: t('messages.epdDepublished', { epdNumber: epdDefinition?.fullIdentificationNumber }) as string,
      });
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toaster({
        severity: 'error',
        summary: 'Error',
        details: 'Depublish of EPD has not been accomplished due to unexpected error.',
      });
    }
  };

  const actionMenuItems: MenuItem[] = [
    {
      label: 'Depublish EPD',
      command: () => setVisibleDialog('depublishByAdmin'),
      visible: actionsStates?.depublishByAdmin === ActionState.Enabled,
    },
    {
      label: 'Depublish EPD',
      command: () => onDepublishEpd(),
      visible: actionsStates?.depublishByCustomer === ActionState.Enabled,
    },
    {
      label: 'De-register EPD',
      command: onDeregisterEpd,
      visible: actionsStates?.deregister === ActionState.Enabled,
    },
    {
      label: 'Download EPD',
      url: EpdLinks.epdVersionDocumentPreview(epdVersionId!),
      target: '_blank',
    },
  ];

  const epdDetailsForDialogs = epdDefinition
    ? {
        fullIdentificationNumber: epdDefinition.fullIdentificationNumber,
        title: epdDefinition.declarationName,
      }
    : null;

  const handleDownloadPDF = () => {
    window.open(EpdLinks.epdVersionDocumentPreview(epdVersionId!), '_blank');
  };

  if (redirect) {
    return redirect;
  }

  return (
    <Container>
      <ConfirmDialog />
      {status === ProcessStatus.Fetching && <OverlaySpinner />}
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <WizardHeaderGrid>
          <TitleAndBack
            title={epdDefinition?.declarationName ?? 'New EPD'}
            backTo={'Back to the dashboard'}
            onBack={onGoBack}
          />
          <WizardHeaderButtonBox>
            <CompilerActionSecondaryButton
              actionState={actionsStates?.cancelUpdate}
              label="Cancel update"
              onClick={onCancelUpdateEpd}
              outlined
              type="reset"
            />
            <CompilerActionSecondaryButton
              actionState={actionsStates?.deleteDraft}
              label="Delete EPD"
              onClick={onDeleteEpd}
              outlined
              type="reset"
            />
            <CompilerActionSecondaryButton
              actionState={actionsStates?.declineVerification}
              label="Decline EPD"
              onClick={() => setVisibleDialog('declineVerification')}
              outlined
            />
            <CompilerActionSecondaryButton
              actionState={actionsStates?.declinePublication}
              label="Decline EPD"
              onClick={() => setVisibleDialog('declinePublication')}
              outlined
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Start verification"
              onClick={onStartVerification}
              actionState={actionsStates?.startVerification}
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Cancel verification"
              onClick={onCancelVerification}
              actionState={actionsStates?.cancelVerification}
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Cancel publication"
              onClick={onCancelRegistrationClicked}
              actionState={actionsStates?.cancelRegistration}
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Send to publish"
              onClick={onRegister}
              actionState={actionsStates?.register}
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Cancel de-registration"
              onClick={onCancelEpdDeregistration}
              actionState={actionsStates?.cancelDeregistration}
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Update EPD"
              onClick={onStartUpdatingEpd}
              actionState={actionsStates?.unlock}
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Send to publish"
              onClick={() => setVisibleDialog('confirmEditorialSendToPublish')}
              actionState={actionsStates?.registerEditorial}
            />
            {actionsStates?.isVerifierInvolved ? (
              <ConfirmEditorialSendToPublishDialog
                isOpened={visibleDialog === 'confirmEditorialSendToPublish'}
                onClose={handleEditorialSendToPublishConfirmed}
              />
            ) : (
              <ConfirmEditorialSendToPublishProcessCertificationDialog
                isOpened={visibleDialog === 'confirmEditorialSendToPublish'}
                onClose={handleEditorialSendToPublishConfirmed}
              />
            )}
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Retract Verification"
              onClick={onRetractVerification}
              actionState={actionsStates?.retractVerification}
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Approve EPD"
              onClick={onApproveVerificationEpd}
              actionState={actionsStates?.approveVerification}
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Publish EPD"
              onClick={onPublishEpd}
              actionState={actionsStates?.publish}
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Approve EPD"
              onClick={onLicenseeAdminApproveEpd}
              actionState={actionsStates?.licenseeAdminVerification}
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Decline EPD"
              onClick={() => setVisibleDialog('declinePublicationLicensee')}
              actionState={actionsStates?.licenseeAdminVerification}
            />
            {actionMenuItems.filter((m) => m.visible).length > 1 ? (
              <ActionImageGreenButton
                style={{ marginLeft: '16px' }}
                onClick={(e: any) => actionMenuRef.current?.toggle(e)}
                actionState={ActionState.Enabled}
              >
                <img alt="menu" src={menuIcon} />
              </ActionImageGreenButton>
            ) : (
              <ActionImageGreenButton
                style={{ marginLeft: '16px' }}
                onClick={handleDownloadPDF}
                actionState={ActionState.Enabled}
              >
                <img alt="menu" src={pdfDownloadIcon} />
              </ActionImageGreenButton>
            )}

            <Menu ref={actionMenuRef} model={actionMenuItems} popupAlignment={'right'} popup />
            <ConfirmDialog
              header={<>Update EPD</>}
              closable={false}
              visible={visibleDialog === 'unlock'}
              style={{ width: '35rem' }}
              onHide={() => setVisibleDialog(null)}
              acceptLabel="Submit"
              rejectLabel="Cancel"
              accept={() => startUpdatingHandler(epdUpdateType)}
              className="custom-dialog"
              message={
                <StyledContentContainer>
                  <HelpBox>
                    <HelpBoxHeader>This EPD was approved by the verifier.</HelpBoxHeader>
                    <div>
                      You are allowed to make only the editorial changes to the verified EPD, such as correction of spelling
                      errors without verification.
                    </div>
                    <div>
                      If the EPD information needs an update, either the product or LCA, you shall verify the EPD again.
                    </div>
                  </HelpBox>
                  <CheckBoxPanel>
                    <RadioButtonStyled
                      inputId={'updateMode_update'}
                      value="update"
                      name="updateMode"
                      checked={epdUpdateType === 'update'}
                      onChange={(e) => {
                        setEpdUpdateType(e.value);
                      }}
                    />
                    <label htmlFor={'updateMode_update'} style={{ textTransform: 'none', marginLeft: '0.5rem' }}>
                      The EPD needs a verification, I will update the EPD information.
                    </label>
                  </CheckBoxPanel>
                  <CheckBoxPanel style={{ marginTop: '0.5rem' }}>
                    <RadioButtonStyled
                      inputId={'updateMode_editorialUpdate'}
                      value="editorialUpdate"
                      name="updateMode"
                      checked={epdUpdateType === 'editorialUpdate'}
                      onChange={(e: RadioButtonChangeEvent) => {
                        setEpdUpdateType(e.value);
                      }}
                    />
                    <label htmlFor={'updateMode_editorialUpdate'} style={{ textTransform: 'none', marginLeft: '0.5rem' }}>
                      The EPD verification is not needed, I will make the editorial update.
                    </label>
                  </CheckBoxPanel>
                </StyledContentContainer>
              }
            />
            <DeclineVerificationDialog
              isOpened={visibleDialog === 'declineVerification'}
              epd={epdDetailsForDialogs}
              onClose={(payload) => {
                setVisibleDialog(null);
                if (!payload) {
                  return;
                }
                onDeclineVerificationEpd(payload);
              }}
            />
            <DeclinePublicationDialog
              isOpened={visibleDialog === 'declinePublication'}
              epd={epdDetailsForDialogs}
              onClose={(payload) => {
                setVisibleDialog(null);
                if (!payload) {
                  return;
                }
                onDeclinePublicationEpd(payload, 'bySuperAdmin');
              }}
            />
            <DeclinePublicationDialog
              isOpened={visibleDialog === 'declinePublicationLicensee'}
              epd={epdDetailsForDialogs}
              onClose={(payload) => {
                setVisibleDialog(null);
                if (!payload) {
                  return;
                }
                onDeclinePublicationEpd(payload, 'byLicensee');
              }}
            />
            <DepublishAdminDialog
              isOpened={visibleDialog === 'depublishByAdmin'}
              epd={epdDetailsForDialogs}
              onClose={(payload) => {
                setVisibleDialog(null);
                if (!payload) {
                  return;
                }
                onDepublishEpd(payload);
              }}
            />
          </WizardHeaderButtonBox>
        </WizardHeaderGrid>

        <WizardContentsGrid>
          <div>
            <StatusBox
              statusDisplayName={actionsStates?.statusDisplayName}
              statusMessageCode={actionsStates?.statusMessageCode}
            />

            <StepsPanelContainer>
              {steps.map((step, index) => (
                <StepPanel
                  key={index}
                  path={step.path}
                  label={step.label}
                  name={step.name}
                  description={step.description}
                  isSelected={stepId === step.id}
                  isValid={step.isValid(compilerCompositeStatus?.errors)}
                />
              ))}
            </StepsPanelContainer>
          </div>
          {/* HACK: The key attribute is supposed to refresh text inputs in uncontrolled components.
                    But it also causes an annoying state reset when the page finishes loading.
          */}
          <MainViewFull key={!!epdDefinition ? 'ready' : 'not ready'}>
            {steps.find((s) => s.id === stepId)?.render()}
          </MainViewFull>
        </WizardContentsGrid>
      </ErrorBoundary>
    </Container>
  );
};

const StyledContentContainer = styled.div`
  padding: 1rem 2rem 2rem 2rem;
  ${(props) => props.theme.fonts.textSmall}
`;

const StepsPanelContainer = styled.div`
  padding: 0;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.04);
`;

const WizardContentsGrid = styled.div`
  display: grid;
  width: 100%;
  margin-bottom: 40px;
  align-items: start;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
  border: 2 solid green;

  ${(props) => props.theme.media.tablet} {
    grid-template-columns: 3fr 10fr;
  }
`;

const MainViewFull = styled.div`
  flex: 0 0 100%;

  ${ErrorTextBox} {
    word-break: break-word;
  }
`;

export default withGreyBackground(EpdCompiler);
