import React from 'react';
import { Column, ColumnProps } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { Row } from 'primereact/row';
import styled from 'styled-components';

type TProps = {
  rows: any[] | undefined;
  columns: ColumnProps[];
  editableFields?: { fieldName: string; label: React.ReactNode; value: string; placeholder: string }[];
  notes: { note: React.ReactNode; description: React.ReactNode }[];
};

const LcaResultsGridPdf = ({ rows, columns, editableFields, notes }: TProps) => {
  const footerGroup = (
    <ColumnGroup>
      {editableFields?.map((x, index) => (
        <Row key={index}>
          <Column footer={x.label} />
          <Column footer={x.value} colSpan={columns.length} />
        </Row>
      ))}
      {notes.map((x, index) => (
        <Row key={index}>
          <Column footer={x.note} />
          <Column footer={x.description} colSpan={columns.length} />
        </Row>
      ))}
    </ColumnGroup>
  );

  return (
    <DataTableStyledPdf value={rows} dataKey="indicatorId" showGridlines footerColumnGroup={footerGroup}>
      {columns.map((x) => (
        <Column key={x.field} {...x} />
      ))}
    </DataTableStyledPdf>
  );
};

export const DataTableStyledPdf = styled(DataTable)`
  display: grid;
  width: 100%;
  border-collapse: 'collapse';

  && tfoot > tr > td.p-row-note {
    font-weight: 400;
    background-color: ${(props) => props.theme.colors.elementsFilledInput};
  }

  .p-datatable-thead > tr > th.module-d,
  .p-datatable-tbody > tr > td.module-d,
  .p-datatable-tfoot > tr > td.module-d {
    background-color: ${(props) => props.theme.colors.elementsFilledInput};
  }

  .p-datatable-header {
    background-color: ${(props) => props.theme.colors.secondaryLightGreen30};
    padding: 12px 1rem;
    align-items: center;
  }
  .p-datatable-thead > tr > th {
    color: var(--Primary-Black, #151515);
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background-color: ${(props) => props.theme.colors.secondaryLightGreen30};
    padding: 0.3rem 0.5rem;
    text-align: center;
  }
  .p-datatable-tbody > tr > td,
  .p-datatable-tfoot > tr > td {
    color: var(--Primary-Black, #151515);
    font-family: Inter;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
    padding: 0.2rem 0.5rem;
  }
  && tfoot > tr > td.p-row-total {
    font-weight: 600;
    background-color: ${(props) => props.theme.colors.elementsTableRow};
  }
  & .p-checkbox .p-checkbox-box {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    border: 1px solid var(--Status-labels-Gray-text, #7f8081);
    & .p-checkbox-icon {
      font-size: 12px;
    }
  }

  & .p-checkbox {
    width: 12px;
    height: 12px;
  }
`;

export default LcaResultsGridPdf;
