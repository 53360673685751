import { LcaResultModel } from 'types/types';
import LcaResultsGrid from './LcaResultsGrid';
import LcaResultsGridPdf from './LcaResultsGridPdf';
import {
  createVariationColumnWithDisclaimer,
  createVariationDisclaimerNote,
  LcaResultsGridColumnsNoCategory,
  LcaResultsGridColumnsNoCategoryPdf,
} from './LcaResultsGridColumns';

type TProps = {
  epdVersionId: string;
  rows: LcaResultModel[] | undefined;
  isForPdf?: boolean;
  disabled?: boolean;
  preferEnabledAppearanceEvenWhenDisabled?: boolean;
  isSingleProductSelected: boolean;
};

const LcaResultsGridWasteConstruction = ({
  epdVersionId,
  rows,
  isForPdf,
  disabled,
  preferEnabledAppearanceEvenWhenDisabled,
  isSingleProductSelected,
}: TProps) => {
  const notes: { note: React.ReactNode; description: React.ReactNode }[] = [
    {
      note: 'Acronyms',
      description: 'HWD = Hazardous waste disposed; NHWD = Non-hazardous waste disposed; RWD = Radioactive waste disposed.',
    },
  ];

  let columns = isForPdf ? LcaResultsGridColumnsNoCategoryPdf : LcaResultsGridColumnsNoCategory;
  if (!isSingleProductSelected && (!isForPdf || (isForPdf && rows?.some((r) => r.variation)))) {
    const disclaimerNumber = 1;
    columns = [...columns, createVariationColumnWithDisclaimer(disclaimerNumber)];
    notes.push(createVariationDisclaimerNote(disclaimerNumber));
  }

  if (isForPdf) {
    return <LcaResultsGridPdf rows={rows} columns={columns} notes={notes} />;
  }
  return (
    <LcaResultsGrid
      epdVersionId={epdVersionId}
      value={rows}
      columns={columns}
      notes={notes}
      disabled={disabled}
      preferEnabledAppearanceEvenWhenDisabled={preferEnabledAppearanceEvenWhenDisabled}
    />
  );
};

export default LcaResultsGridWasteConstruction;
