import { CompanyContext } from 'contexts/CompanyContextProvider';
import React, { useContext, useEffect, useState } from 'react';
import { EPDModel, FileLoadingModel } from 'services/EpdClient';
import EPDService from 'services/EpdService';
import {
  addEpdFile,
  useAddCoverPageFile,
  useAddOrganizationInformationFile,
  useRemoveCoverPageFile,
  useRemoveOrganizationInformationFile,
  useUpdateEpdOrganizationInformation,
  useUpdateEpdOrganizationInformationAddress,
} from 'services/api/mutations';
import {
  useCompanyUsersLookup,
  useCountriesLookup,
  useEpdCoverPageInformation,
  useEpdOrganizationInformation,
  useEpdOrganizationInformationAddress,
} from 'services/api/queries';
import { FieldPanel, FieldPanelFullWidth, FieldPanelHeader } from 'styles/v2/Styles.styled';
import { Option, UserOptionModel } from 'types/types';

import CompilerSelectControlledComponent from '../epd-compiler-inputs/CompilerSelectControlledComponent';
import WizardTextAreaComponent from '../epd-wizard-inputs/WizardTextArea';
import WizardTextInputComponent from '../epd-wizard-inputs/WizardTextInput';
import WizardTextInputControlledComponent from '../epd-wizard-inputs/WizardTextInputControlled';
import WizardTextInputWithAutoComplete from '../epd-wizard-inputs/WizardTextInputWithAutoComplete';
import { ImagesUpload } from '../images-upload';
import SmartLabel from '../labels/SmartLabel';
import { ErrorAlertPanel } from './panels/Styles.styled';
import TooltipErrorIcon from '../icons/TooltipErrorIcon';

const OrganizationTab: React.FunctionComponent<{
  epdId: string;
  epdVersionId: string;
  validationState: any;
  canUpload: boolean;
  isReadOnly: boolean;
  fileLoading?: FileLoadingModel[];
}> = ({ epdId, epdVersionId, validationState }) => {
  const { companyId, company } = useContext(CompanyContext);

  const countries = useCountriesLookup().data;
  const epdOrganizationAddress = useEpdOrganizationInformationAddress(epdVersionId).data;
  const organizationInformation = useEpdOrganizationInformation(epdVersionId).data;

  const coverPageInformation = useEpdCoverPageInformation(epdVersionId).data;
  const addCoverPageFileMutation = useAddCoverPageFile(epdVersionId);
  const deleteCoverPageFileMutation = useRemoveCoverPageFile(epdVersionId);

  const contactUsersLookup = useCompanyUsersLookup(companyId as string).data;
  const updateMutation = useUpdateEpdOrganizationInformation(epdVersionId);
  const updateAddressMutation = useUpdateEpdOrganizationInformationAddress(epdVersionId);
  const addOrganizationInformationFileMutation = useAddOrganizationInformationFile(epdVersionId);
  const removeOrganizationInformationFileMutation = useRemoveOrganizationInformationFile(epdVersionId);

  const [suggestedUsers, setSuggestedUsers] = useState<string[] | undefined>([]);
  const [selectedContactPersonName, setSelectedContactPersonName] = useState<string | undefined>(
    organizationInformation?.contactPersonName
  );

  useEffect(() => {
    setSelectedContactPersonName(organizationInformation?.contactPersonName);
  }, [organizationInformation?.contactPersonName]);

  const onAddCoverPageFile = async (propertyName: string, file: File) => {
    await addEpdFile({ epdId: epdId, propertyName, file }, addCoverPageFileMutation);
  };

  const onChangeEpdContactPersonEmail = async (propertyName: string, value: any) => {
    const contactUserName = contactUsersLookup?.find(
      (user: UserOptionModel) => user.label.toLowerCase() === value.toLowerCase()
    )?.userName;

    if (contactUserName && contactUserName !== selectedContactPersonName) {
      setSelectedContactPersonName(contactUserName);
    }

    if (value !== organizationInformation?.contactPersonEmail) {
      onChangeEpdOrganizationInformation(propertyName, value);
    }
  };

  const onSelectSuggestedEpdContactPersonEmail = async (value: any) => {
    const contactUserName = contactUsersLookup?.find(
      (user: UserOptionModel) => user.label.toLowerCase() === value.toLowerCase()
    )?.userName;

    if (contactUserName && contactUserName !== selectedContactPersonName) {
      setSelectedContactPersonName(contactUserName);
    }
  };

  const onChangeEpdOrganizationInformation = async (propertyName: string, value: any) => {
    updateMutation.mutate({ epdId: epdId, versionId: epdVersionId, propertyName: propertyName, newValue: value });
  };

  const onAddOrganizationInformationFile = async (propertyName: string, file: File) => {
    await addEpdFile({ epdId: epdId, propertyName, file }, addOrganizationInformationFileMutation);
  };

  const onRemoveOrganizationInformationFile = async (fileId: string) => {
    removeOrganizationInformationFileMutation.mutate(fileId);
  };

  const onRemoveCoverPageFile = async (fileId: string) => {
    deleteCoverPageFileMutation.mutate(fileId);
  };

  const onChangeEpdOrganizationInformationAddress = async (propertyName: string, val: any) => {
    updateAddressMutation.mutate({ epdId: epdId, versionId: epdVersionId, propertyName: propertyName, newValue: val });
  };

  const onChangeCountry = (propName: string, value: any) => {
    if (Array.isArray(countries)) {
      onChangeEpdOrganizationInformationAddress(propName, value);
    } else {
      onChangeEpdOrganizationInformationAddress(propName, undefined);
    }
  };

  const selectedCountryOrginal = countries?.find((country: any) => country.id === epdOrganizationAddress?.countryId) ?? null;

  const selectedCountry = epdOrganizationAddress?.countryId
    ? ({
        value: selectedCountryOrginal?.id,
        label: selectedCountryOrginal?.name,
      } as Option)
    : null;

  const searchDevelopersEmail = (event: any) => {
    setSuggestedUsers(
      contactUsersLookup
        ?.filter((user: UserOptionModel) => user.label.toLowerCase().startsWith(event.query.toLowerCase()))
        .map((filteredDev: UserOptionModel) => filteredDev.label)
    );
  };

  return (
    <>
      <FieldPanelFullWidth>
        <FieldPanelHeader>Contact information</FieldPanelHeader>
        <WizardTextInputComponent
          label="EPD owner"
          tooltip="User who is appointed as EPD owner in the organisation who creates the EPD."
          name="companyName"
          disabled
          value={company?.name}
        />
        <WizardTextInputWithAutoComplete
          label="Contact person e-mail"
          tooltip="Please select which team member should be the main contact for EPD queries."
          value={organizationInformation?.contactPersonEmail}
          onChanged={onChangeEpdContactPersonEmail}
          onSelectSuggested={onSelectSuggestedEpdContactPersonEmail}
          required
          placeholder="Type here"
          name="contactPersonEmail"
          suggestions={suggestedUsers}
          completeMethod={searchDevelopersEmail}
          error={validationState?.errors?.organizationInformation?.contactPersonEmail}
        />
        <WizardTextInputControlledComponent
          label="Contact person name"
          tooltip="Person name who is should be the main contact for EPD queries."
          onChanged={onChangeEpdOrganizationInformation}
          onChange={(e: any) => setSelectedContactPersonName(e.target.value)}
          required
          placeholder="Name and last name"
          name="contactPersonName"
          value={selectedContactPersonName ?? undefined}
          error={validationState?.errors?.organizationInformation?.contactPersonName}
        />
      </FieldPanelFullWidth>
      <FieldPanel>
        <FieldPanelHeader className="w-full">
          Organisation address
          {!!validationState?.errors?.organizationInformationAddress && (
            <TooltipErrorIcon content={validationState?.errors?.organizationInformationAddress} />
          )}
        </FieldPanelHeader>

        {validationState?.errors?.organizationInformationAddress?.countryDiffersFromCompany && (
          <ErrorAlertPanel className="w-full">
            {validationState.errors.organizationInformationAddress.countryDiffersFromCompany}
          </ErrorAlertPanel>
        )}

        <CompilerSelectControlledComponent
          label="Country"
          name="countryId"
          options={countries?.map((country: any) => {
            return {
              value: country?.id,
              label: country?.name,
            } as Option;
          })}
          required
          value={selectedCountry}
          onChanged={onChangeCountry}
          placeholder="Select..."
          error={
            validationState?.errors?.organizationInformationAddress?.country ||
            validationState?.errors?.organizationInformationAddress?.countryDiffersFromCompany
          }
        />

        <WizardTextInputComponent
          label="ZIP code"
          tooltipPosition={'right'}
          required
          placeholder="Type here"
          value={epdOrganizationAddress?.zipCode}
          onChanged={onChangeEpdOrganizationInformationAddress}
          name="zipCode"
          error={validationState?.errors?.organizationInformationAddress?.zipCode}
        />
        <WizardTextInputComponent
          label="City"
          required
          placeholder="Type here"
          value={epdOrganizationAddress?.city}
          onChanged={onChangeEpdOrganizationInformationAddress}
          name="city"
          error={validationState?.errors?.organizationInformationAddress?.city}
        />
        <WizardTextInputComponent
          label="Address line 1"
          required
          placeholder="Type here"
          value={epdOrganizationAddress?.addressLine}
          onChanged={onChangeEpdOrganizationInformationAddress}
          name="address"
          error={validationState?.errors?.organizationInformationAddress?.addressLine}
        />
        <WizardTextInputComponent
          label="Address line 2"
          placeholder="Type here"
          value={epdOrganizationAddress?.addressLineSecond}
          onChanged={onChangeEpdOrganizationInformationAddress}
          name="addressSecond"
        />
      </FieldPanel>
      <FieldPanelFullWidth>
        <FieldPanelHeader>Organisation details</FieldPanelHeader>

        <WizardTextAreaComponent
          label="Description of the organisation"
          placeholder="Type here"
          tooltip="This may include information on products - or management system-related certifications (e.g. ISO 14024 Type I environmental labels, ISO 9001- and 14001-certificates and EMAS-registrations) and other relevant work the organisation wants to communicate (e.g. SA 8000, supply-chain management and social responsibility)."
          name="description"
          value={organizationInformation?.description}
          onChanged={onChangeEpdOrganizationInformation}
          error={validationState?.errors?.organizationInformation?.description}
          required
        />
        <div>
          <SmartLabel
            label={'Organisation logo'}
            required
            hasValue={coverPageInformation?.companyLogoImages && coverPageInformation?.companyLogoImages.length > 0}
            error={validationState?.errors?.companyLogoImages}
          />
          <ImagesUpload
            name={'CompanyLogoImages'}
            single
            images={coverPageInformation?.companyLogoImages}
            onUpload={onAddCoverPageFile}
            onReorder={(imgIds: string[]) => EPDService.arrangeEpdFilesByModifyDate(imgIds)}
            onRemove={onRemoveCoverPageFile}
          />
        </div>

        <div>
          <SmartLabel
            label={'Organisation images'}
            tooltip="Images which help to describe and identify your organisation."
          />
          <ImagesUpload
            name={'OrganizationImages'}
            images={organizationInformation?.organizationImages}
            onUpload={onAddOrganizationInformationFile}
            onReorder={(imgIds: string[]) => EPDService.arrangeEpdFilesByModifyDate(imgIds)}
            onRemove={onRemoveOrganizationInformationFile}
          />
        </div>
      </FieldPanelFullWidth>
    </>
  );
};

export default OrganizationTab;
