import { useEpdLcaResultsAdditional } from 'services/api/queries';
import {
  createVariationColumnWithDisclaimer,
  createVariationDisclaimerNote,
  LcaResultsAdditionalGridColumns,
  LcaResultsAdditionalGridColumnsPdf,
} from './LcaResultsGridColumns';
import LcaResultsGridExtended from './LcaResultsGridExtended';
import LcaResultsGridExtendedPdf from './LcaResultsGridExtendedPdf';

type TProps = {
  epdVersionId: string;
  isForPdf?: boolean;
  disabled?: boolean;
  preferEnabledAppearanceEvenWhenDisabled?: boolean;
  isSingleProductSelected: boolean;
};

const LcaResultsGridAdditionalVoluntary = ({
  epdVersionId,
  isForPdf,
  disabled,
  preferEnabledAppearanceEvenWhenDisabled,
  isSingleProductSelected,
}: TProps) => {
  const rows = useEpdLcaResultsAdditional(epdVersionId).data;

  const notes: { note: React.ReactNode; description: React.ReactNode }[] = [];
  let columns = isForPdf ? LcaResultsAdditionalGridColumnsPdf : LcaResultsAdditionalGridColumns;
  if (!isSingleProductSelected && (!isForPdf || (isForPdf && rows?.some((r) => r.variation)))) {
    const disclaimerNumber = 1;
    columns = [...columns, createVariationColumnWithDisclaimer(disclaimerNumber)];
    notes.push(createVariationDisclaimerNote(disclaimerNumber));
  }

  if (isForPdf) {
    return <LcaResultsGridExtendedPdf epdVersionId={epdVersionId} rows={rows} columns={columns} notes={notes} />;
  }
  return (
    <LcaResultsGridExtended
      epdVersionId={epdVersionId}
      rows={rows}
      columns={columns}
      notes={notes}
      disabled={disabled}
      preferEnabledAppearanceEvenWhenDisabled={preferEnabledAppearanceEvenWhenDisabled}
    />
  );
};

export default LcaResultsGridAdditionalVoluntary;
