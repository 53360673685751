import ErrorFallback from 'components/error-fallback/ErrorFallback';
import { confirmDelete } from 'components/v2/confirm-dialog/ConfirmDelete';
import FileUpload from 'components/v2/file-upload/FileUpload';
import { ButtonUploadIcon } from 'components/v2/icons';
import { CUSTOM_RESULT_KEYS, EPD_LCA_TEMPLATES_URL } from 'constants/constants';
import React, { ChangeEvent } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { EPD_DICTIONARY_CODES, INDICATOR_GROUP_CODES } from 'services/api/constants';
import { addEpdFile, useAddEpdLcaResultFile, useRemoveEpdLcaResultFile } from 'services/api/mutations';
import {
  useCreateLcaCustomResult,
  useDeleteLcaCustomResult,
  useUpdateLcaCustomResult,
} from 'services/api/mutationsLcaResults';
import {
  useDictionaryValues,
  useEpdLcaCustomResults,
  useEpdLcaResults,
  useEpdScenarios,
  useGetInfrastructureAndCapitalGoods,
} from 'services/api/queries';
import { HyperLink } from 'styles/Styles.styled';
import { FieldContainer, FieldPanelFullWidth } from 'styles/v2/Styles.styled';
import { LcaCustomResultModel, Option } from 'types/types';
import { getOptionsFromDictionary } from 'util/utils';

import { TextButton } from '../buttons';
import { AddCustomBlockButton } from '../buttons/AddCustomBlockButton';
import CompilerSelectControlledComponent from '../epd-compiler-inputs/CompilerSelectControlledComponent';
import WizardTextAreaComponent from '../epd-wizard-inputs/WizardTextArea';
import WizardTextInputComponent from '../epd-wizard-inputs/WizardTextInput';
import FilesList from '../file-row/FilesList';
import { HelpBox } from '../help-boxes';
import { YES_NO } from './constants';
import LcaResultsGridAdditionalConstruction from './grids/lca-results/LcaResultsGridAdditionalConstruction';
import LcaResultsGridAdditionalMandatory from './grids/lca-results/LcaResultsGridAdditionalMandatory';
import LcaResultsGridAdditionalVoluntary from './grids/lca-results/LcaResultsGridAdditionalVoluntary';
import LcaResultsGridCoreConstruction from './grids/lca-results/LcaResultsGridCoreConstruction';
import LcaResultsGridOutputFlowConstruction from './grids/lca-results/LcaResultsGridOutputFlowConstruction';
import LcaResultsGridResourceConstruction from './grids/lca-results/LcaResultsGridResourceConstruction';
import LcaResultsGridWasteConstruction from './grids/lca-results/LcaResultsGridWasteConstruction';
import ValidateErrorsContainer from './grids/lca-results/ValidateErrorsContainer';
import LcaCustomBestWorstResultsGrid from './grids/lca-results/custom/LcaCustomBestWorstResultsGrid';
import LcaCustomBiogenicResultsGrid from './grids/lca-results/custom/LcaCustomBiogenicResultsGrid';
import LcaCustomAdditionalResultsGrid from './grids/lca-results/custom/LcaCustomAdditionalResultsGrid';
import LcaGridErrorsContext from './grids/lca-results/util/LcaGridErrorsContext';
import useIsReadOnlyMode from './hooks/useIsReadOnlyMode';
import GridExpandablePanel from './panels/expandable/GridExpandablePanel';
import ToggleablePanel from './panels/toggleable/ToggleablePanel';

const LcaResultsTab: React.FunctionComponent<{
  epdId: string;
  epdVersionId: string;
  errors?: any;
  isSingleProductDeclaration: boolean;
  isCore15804ValidateBtnPressed: boolean;
  setIsCore15804ValidateBtnPressed: any;
  isAdditionalMandatoryValidateBtnPressed: boolean;
  setIsAdditionalMandatoryValidateBtnPressed: any;
  isAdditionalVoluntary15804ValidateBtnPressed: boolean;
  setIsAdditionalVoluntary15804ValidateBtnPressed: any;
  isAdditionalVoluntaryValidateBtnPressed: boolean;
  setIsAdditionalVoluntaryValidateBtnPressed: any;
  isResource15804ValidateBtnPressed: boolean;
  setIsResource15804ValidateBtnPressed: any;
  isWaste15804ValidateBtnPressed: boolean;
  setIsWaste15804ValidateBtnPressed: any;
  isOutputFlow15804ValidateBtnPressed: boolean;
  setIsOutputFlow15804ValidateBtnPressed: any;
}> = ({
  epdId,
  epdVersionId,
  errors,
  isSingleProductDeclaration,
  isCore15804ValidateBtnPressed,
  setIsCore15804ValidateBtnPressed,
  isAdditionalMandatoryValidateBtnPressed,
  setIsAdditionalMandatoryValidateBtnPressed,
  isAdditionalVoluntary15804ValidateBtnPressed,
  setIsAdditionalVoluntary15804ValidateBtnPressed,
  isAdditionalVoluntaryValidateBtnPressed,
  setIsAdditionalVoluntaryValidateBtnPressed,
  isResource15804ValidateBtnPressed,
  setIsResource15804ValidateBtnPressed,
  isWaste15804ValidateBtnPressed,
  setIsWaste15804ValidateBtnPressed,
  isOutputFlow15804ValidateBtnPressed,
  setIsOutputFlow15804ValidateBtnPressed,
}) => {
  const lcaResultsInformation = useEpdLcaResults(epdVersionId!).data;
  const lcaCustomResults = useEpdLcaCustomResults(epdVersionId!).data;
  const infrastructureAndCapitalGoods = useGetInfrastructureAndCapitalGoods(epdVersionId).data;
  const createLcaCustomResultsMutation = useCreateLcaCustomResult();
  const deleteLcaCustomResultsMutation = useDeleteLcaCustomResult();
  const updateLcaCustomResultsMutation = useUpdateLcaCustomResult(epdVersionId);
  const scenariosLookup = (useEpdScenarios(epdVersionId).data || [])
    .filter((x) => !x.isDefaultScenario)
    .map((s) => {
      return {
        value: s.id,
        label: s.name ?? '<unnamed>',
      } as Option;
    });
  const addLcaResultFileMutation = useAddEpdLcaResultFile(epdVersionId);
  const removeLcaResultFileMutation = useRemoveEpdLcaResultFile(epdVersionId);

  const isReadOnly = useIsReadOnlyMode();

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>, propertyName: string) => {
    const target = e.target as HTMLInputElement;
    let file = target && target.files && target.files[0];
    if (!file) {
      return null;
    }
    await addEpdFile({ epdId, propertyName, file }, addLcaResultFileMutation);
  };

  const onRemoveLcaResultFile = async (removeCommandAlias: string, fileId: string) => {
    removeLcaResultFileMutation.mutate(fileId);
  };

  const handleCreateCustomLcaResults = () => {
    createLcaCustomResultsMutation.mutate({ epdVersionId });
  };

  const confirmDeleteCustomLcaResults = (id: string) => {
    confirmDelete({
      caption: `Are you sure you want to delete custom results section?`,
      onConfirm: () => deleteLcaCustomResultsMutation.mutate(id),
    });
  };

  const handleOnChangeCustomLcaResult = (customResult: LcaCustomResultModel) => (propertyName: string, val: any) => {
    let updateData = { ...customResult, [propertyName]: val };
    updateLcaCustomResultsMutation.mutate(updateData);
  };

  const dictionariesQuery = useDictionaryValues();
  const additionalPerformanceResultsTypeLookup = getOptionsFromDictionary(
    dictionariesQuery.data,
    EPD_DICTIONARY_CODES.ADDITIONAL_LCA_RESULTS_TYPE
  ).map((x) => {
    return {
      ...x,
      isDisabled: isSingleProductDeclaration && (x.value == CUSTOM_RESULT_KEYS.BEST || x.value == CUSTOM_RESULT_KEYS.WORST),
    };
  });

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <FieldPanelFullWidth style={{ gridRowGap: 16 }}>
        <div>
          <HelpBox>
            <span>
              The LCA results can be either directly added manually to the tables below or autofilled based upon uploaded LCA
              results document in the excel format. The excel template can be found on{' '}
              <HyperLink target="_blank" rel="noopener noreferrer" href={EPD_LCA_TEMPLATES_URL}>
                environdec.com
              </HyperLink>
            </span>
          </HelpBox>
          <FileUpload
            label={'Upload LCA results'}
            icon={ButtonUploadIcon}
            accept=".xlsx"
            validExtensions={['xlsx']}
            name={'LCA'}
            onFileUpload={uploadFile}
            disabled={
              (lcaResultsInformation && lcaResultsInformation.lcAs.length > 0) || addLcaResultFileMutation.isPending
            }
          />
        </div>
        <FilesList files={lcaResultsInformation?.lcAs} onRemoveFile={onRemoveLcaResultFile} />
      </FieldPanelFullWidth>

      <GridExpandablePanel
        title="Mandatory environmental performance indicators according to EN 15804"
        tooltip="Environmental performance results shall declare the results of the indicators, and the corresponding disclaimers, of EN 15804:2012+A2:2019/AC:2021."
        error={errors?.core15804}
      >
        <LcaGridErrorsContext.Provider value={isCore15804ValidateBtnPressed && errors?.core15804}>
          {errors?.core15804 && <ValidateErrorsContainer onValidate={() => setIsCore15804ValidateBtnPressed(true)} />}
          <LcaResultsGridCoreConstruction
            epdVersionId={epdVersionId}
            rows={lcaResultsInformation?.lcaResults?.filter((x) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.CORE15804)}
            isAdditionalDisclaimerVisible={infrastructureAndCapitalGoods?.includedInProcesses === YES_NO.YES}
            isSingleProductSelected={isSingleProductDeclaration}
          />
        </LcaGridErrorsContext.Provider>
      </GridExpandablePanel>

      <GridExpandablePanel
        title="Additional mandatory environmental performance indicators"
        tooltip="GWP-GHG accounts for all greenhouse gases except biogenic carbon dioxide uptake and emissions and biogenic carbon stored in the product. As such, the indicator is identical to GWP-total except that the CF for biogenic CO2 is set to zero."
        error={errors?.additionalMandatory}
      >
        <LcaGridErrorsContext.Provider value={isAdditionalMandatoryValidateBtnPressed && errors?.additionalMandatory}>
          {errors?.additionalMandatory && (
            <ValidateErrorsContainer onValidate={() => setIsAdditionalMandatoryValidateBtnPressed(true)} />
          )}
          <LcaResultsGridAdditionalMandatory
            epdVersionId={epdVersionId}
            rows={lcaResultsInformation?.lcaResults?.filter(
              (x) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.ADDITIONALMANDATORY
            )}
            isSingleProductSelected={isSingleProductDeclaration}
          />
        </LcaGridErrorsContext.Provider>
      </GridExpandablePanel>

      <GridExpandablePanel
        title="Additional voluntary environmental performance indicators according to EN 15804"
        error={errors?.additionalVoluntary15804}
      >
        <LcaGridErrorsContext.Provider
          value={isAdditionalVoluntary15804ValidateBtnPressed && errors?.additionalVoluntary15804}
        >
          {errors?.additionalVoluntary15804 && (
            <ValidateErrorsContainer onValidate={() => setIsAdditionalVoluntary15804ValidateBtnPressed(true)} />
          )}
          <LcaResultsGridAdditionalConstruction
            epdVersionId={epdVersionId}
            rows={lcaResultsInformation?.lcaResults?.filter(
              (x) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.ADDITIONALVOLUNTARY15804
            )}
            isAdditionalDisclaimerVisible={infrastructureAndCapitalGoods?.includedInProcesses === YES_NO.YES}
            isSingleProductSelected={isSingleProductDeclaration}
          />
        </LcaGridErrorsContext.Provider>
      </GridExpandablePanel>

      <GridExpandablePanel
        title="Additional voluntary environmental performance indicators"
        error={errors?.additionalVoluntary}
      >
        <LcaGridErrorsContext.Provider value={isAdditionalVoluntaryValidateBtnPressed && errors?.additionalVoluntary}>
          {errors?.additionalVoluntary && (
            <ValidateErrorsContainer onValidate={() => setIsAdditionalVoluntaryValidateBtnPressed(true)} />
          )}
          <LcaResultsGridAdditionalVoluntary
            epdVersionId={epdVersionId}
            isSingleProductSelected={isSingleProductDeclaration}
          />
        </LcaGridErrorsContext.Provider>
      </GridExpandablePanel>

      <GridExpandablePanel title="Resource use indicators according to EN 15804" error={errors?.resource15804}>
        <LcaGridErrorsContext.Provider value={isResource15804ValidateBtnPressed && errors?.resource15804}>
          {errors?.resource15804 && (
            <ValidateErrorsContainer onValidate={() => setIsResource15804ValidateBtnPressed(true)} />
          )}
          <LcaResultsGridResourceConstruction
            epdVersionId={epdVersionId}
            rows={lcaResultsInformation?.lcaResults?.filter(
              (x) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.RESOURCE15804
            )}
            isSingleProductSelected={isSingleProductDeclaration}
          />
        </LcaGridErrorsContext.Provider>
      </GridExpandablePanel>

      <GridExpandablePanel title="Waste indicators according to EN 15804" error={errors?.waste15804}>
        <LcaGridErrorsContext.Provider value={isWaste15804ValidateBtnPressed && errors?.waste15804}>
          {errors?.waste15804 && <ValidateErrorsContainer onValidate={() => setIsWaste15804ValidateBtnPressed(true)} />}
          <LcaResultsGridWasteConstruction
            epdVersionId={epdVersionId}
            rows={lcaResultsInformation?.lcaResults?.filter(
              (x) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.WASTE15804
            )}
            isSingleProductSelected={isSingleProductDeclaration}
          />
        </LcaGridErrorsContext.Provider>
      </GridExpandablePanel>

      <GridExpandablePanel title="Output flow indicators according to EN 15804" error={errors?.outputFlow15804}>
        <LcaGridErrorsContext.Provider value={isOutputFlow15804ValidateBtnPressed && errors?.outputFlow15804}>
          {errors?.outputFlow15804 && (
            <ValidateErrorsContainer onValidate={() => setIsOutputFlow15804ValidateBtnPressed(true)} />
          )}
          <LcaResultsGridOutputFlowConstruction
            epdVersionId={epdVersionId}
            rows={lcaResultsInformation?.lcaResults?.filter(
              (x) => x.indicatorGroupCode === INDICATOR_GROUP_CODES.OUTPUTFLOW15804
            )}
            isSingleProductSelected={isSingleProductDeclaration}
          />
        </LcaGridErrorsContext.Provider>
      </GridExpandablePanel>

      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {lcaCustomResults?.map((customResult: LcaCustomResultModel) => {
          const title =
            additionalPerformanceResultsTypeLookup.find((x) => x.value == customResult.resultType)?.label ??
            'Additional environmental performance results';
          return (
            <ToggleablePanel
              key={customResult.id}
              title={title}
              headerActionsNode={
                !isReadOnly && (
                  <TextButton
                    type="button"
                    text
                    icon="pi pi-trash"
                    onClick={() => confirmDeleteCustomLcaResults(customResult.id)}
                  />
                )
              }
              style={customResult.resultType ? { overflowX: 'hidden' } : {}} // this is to be changed: conflict with dropdown and grid display
            >
              <CompilerSelectControlledComponent
                label="Select the type of additional results"
                name="resultType"
                options={additionalPerformanceResultsTypeLookup}
                value={additionalPerformanceResultsTypeLookup.find((x) => x.value == customResult.resultType)}
                onChanged={handleOnChangeCustomLcaResult(customResult)}
                placeholder="Select..."
                isClearable={false}
              />
              {customResult.resultType === CUSTOM_RESULT_KEYS.A4C4 && (
                <FieldContainer>
                  <CompilerSelectControlledComponent
                    label="Additional scenario"
                    name="scenarioId"
                    options={scenariosLookup}
                    value={scenariosLookup.optionByValue(customResult.scenarioId)}
                    onChanged={handleOnChangeCustomLcaResult(customResult)}
                    placeholder="Select..."
                    isClearable={false}
                  />
                </FieldContainer>
              )}

              {customResult.resultType === CUSTOM_RESULT_KEYS.BEST && (
                <FieldContainer>
                  <WizardTextInputComponent
                    label="best-case product"
                    name="bestCaseProduct"
                    value={customResult.bestCaseProduct}
                    onChanged={handleOnChangeCustomLcaResult(customResult)}
                    maxLength={150}
                  />
                </FieldContainer>
              )}
              {customResult.resultType === CUSTOM_RESULT_KEYS.WORST && (
                <FieldContainer>
                  <WizardTextInputComponent
                    label="worst-case product"
                    name="worstCaseProduct"
                    value={customResult.worstCaseProduct}
                    onChanged={handleOnChangeCustomLcaResult(customResult)}
                    maxLength={150}
                  />
                </FieldContainer>
              )}

              {customResult.resultType && (
                <>
                  <FieldContainer>
                    <WizardTextAreaComponent
                      label="Description of the scenario/method"
                      placeholder="Type here"
                      name="scenarioDescription"
                      value={customResult.scenarioDescription}
                      onChanged={handleOnChangeCustomLcaResult(customResult)}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <HelpBox>
                      The description should explain how the additional results differ from the scenario/method of the main
                      environmental performance results
                    </HelpBox>
                  </FieldContainer>
                </>
              )}
              {[CUSTOM_RESULT_KEYS.A4C4, CUSTOM_RESULT_KEYS.MODELING, CUSTOM_RESULT_KEYS.ELECTRICITY].includes(
                customResult.resultType
              ) && (
                <FieldContainer>
                  <LcaCustomAdditionalResultsGrid
                    epdVersionId={epdVersionId}
                    customResult={customResult}
                    isSingleProductSelected={isSingleProductDeclaration}
                    onChangedCustomResult={handleOnChangeCustomLcaResult(customResult)}
                  />
                </FieldContainer>
              )}

              {customResult.resultType === CUSTOM_RESULT_KEYS.BIOGENIC && (
                <FieldContainer>
                  <LcaCustomBiogenicResultsGrid
                    title={title}
                    epdVersionId={epdVersionId}
                    customResultId={customResult.id}
                    isSingleProductSelected={isSingleProductDeclaration}
                  />
                </FieldContainer>
              )}
              {[CUSTOM_RESULT_KEYS.BEST, CUSTOM_RESULT_KEYS.WORST].includes(customResult.resultType) && (
                <FieldContainer>
                  <LcaCustomBestWorstResultsGrid
                    epdVersionId={epdVersionId}
                    customResultId={customResult.id}
                    isSingleProductSelected={isSingleProductDeclaration}
                  />
                </FieldContainer>
              )}
            </ToggleablePanel>
          );
        })}
      </ErrorBoundary>

      {!isReadOnly && (
        <AddCustomBlockButton
          onClick={handleCreateCustomLcaResults}
          caption="Add additional environmental performance results"
        />
      )}
    </ErrorBoundary>
  );
};

export default LcaResultsTab;
