import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TabPanel } from 'primereact/tabview';
import { useEPDDefinitionInformation } from 'services/api/queries';
import { TabViewStyled } from 'styles/v2/Styles.styled';
import { EpdLinks } from 'routes/EpdRoutes';
import LcaResultsTab from './LcaResultsTab';
import ScenariosTab from './ScenariosTab';
import SpecificationTab from './SpecificationTab';
import SystemBoundaryTab from './SystemBoundaryTab';
import { SINGLE_PRODUCT_ID } from './constants';
import { compilerStep3Tabs } from './compilerNavConstants';

const Step3: React.FunctionComponent<{
  epdId: string;
  epdVersionId: string;
  tab: string | null | undefined;
  validationState: any;
}> = ({ epdId, epdVersionId, tab, validationState }) => {
  const history = useHistory();

  let tabIndex = compilerStep3Tabs.findIndex((t) => t === tab);
  if (tabIndex === -1) {
    tabIndex = 0;
  }

  const epdDefinitionInformation = useEPDDefinitionInformation(epdVersionId).data;

  const [isCore15804ValidateBtnPressed, setIsCore15804ValidateBtnPressed] = useState(false);
  const [isAdditionalMandatoryValidateBtnPressed, setIsAdditionalMandatoryValidateBtnPressed] = useState(false);
  const [isAdditionalVoluntary15804ValidateBtnPressed, setIsAdditionalVoluntary15804ValidateBtnPressed] = useState(false);
  const [isAdditionalVoluntaryValidateBtnPressed, setIsAdditionalVoluntaryValidateBtnPressed] = useState(false);
  const [isResource15804ValidateBtnPressed, setIsResource15804ValidateBtnPressed] = useState(false);
  const [isWaste15804ValidateBtnPressed, setIsWaste15804ValidateBtnPressed] = useState(false);
  const [isOutputFlow15804ValidateBtnPressed, setIsOutputFlow15804ValidateBtnPressed] = useState(false);

  return (
    <TabViewStyled
      activeIndex={tabIndex}
      onTabChange={(e) =>
        history.push(EpdLinks.epdCompilerSpecific(epdId, { step: 'step3', tab: compilerStep3Tabs[e.index] }))
      }
    >
      <TabPanel header="Specification">
        <SpecificationTab epdId={epdId} epdVersionId={epdVersionId} validationState={validationState} />
      </TabPanel>
      <TabPanel header="System boundary">
        <SystemBoundaryTab epdId={epdId} epdVersionId={epdVersionId} validationState={validationState} />
      </TabPanel>
      <TabPanel header="Scenarios">
        <ScenariosTab epdVersionId={epdVersionId} validationState={validationState} />
      </TabPanel>
      <TabPanel header="Results">
        <LcaResultsTab
          epdId={epdId}
          epdVersionId={epdVersionId}
          errors={validationState?.errors?.lcaResults}
          isSingleProductDeclaration={
            (epdDefinitionInformation?.epdClassification ?? SINGLE_PRODUCT_ID) == SINGLE_PRODUCT_ID
          }
          isCore15804ValidateBtnPressed={isCore15804ValidateBtnPressed}
          setIsCore15804ValidateBtnPressed={setIsCore15804ValidateBtnPressed}
          isAdditionalMandatoryValidateBtnPressed={isAdditionalMandatoryValidateBtnPressed}
          setIsAdditionalMandatoryValidateBtnPressed={setIsAdditionalMandatoryValidateBtnPressed}
          isAdditionalVoluntary15804ValidateBtnPressed={isAdditionalVoluntary15804ValidateBtnPressed}
          setIsAdditionalVoluntary15804ValidateBtnPressed={setIsAdditionalVoluntary15804ValidateBtnPressed}
          isAdditionalVoluntaryValidateBtnPressed={isAdditionalVoluntaryValidateBtnPressed}
          setIsAdditionalVoluntaryValidateBtnPressed={setIsAdditionalVoluntaryValidateBtnPressed}
          isResource15804ValidateBtnPressed={isResource15804ValidateBtnPressed}
          setIsResource15804ValidateBtnPressed={setIsResource15804ValidateBtnPressed}
          isWaste15804ValidateBtnPressed={isWaste15804ValidateBtnPressed}
          setIsWaste15804ValidateBtnPressed={setIsWaste15804ValidateBtnPressed}
          isOutputFlow15804ValidateBtnPressed={isOutputFlow15804ValidateBtnPressed}
          setIsOutputFlow15804ValidateBtnPressed={setIsOutputFlow15804ValidateBtnPressed}
        />
      </TabPanel>
    </TabViewStyled>
  );
};

export default Step3;
