import React from 'react';
import ConfirmWithCommentDialog, { ConfirmWithCommentPayload } from './ConfirmWithCommentDialog';
import { HelpBox } from '../help-boxes';

interface EPDDetails {
  title?: string;
  fullIdentificationNumber?: string;
}

type Props = {
  epd: EPDDetails | null | undefined;
  isOpened: boolean;
  onClose(payload: ConfirmWithCommentPayload | null): void;
};

const DeclineVerificationDialog: React.FC<Props> = ({ epd, isOpened, onClose }) => {
  return (
    <ConfirmWithCommentDialog
      header="Decline EPD verification"
      help={
        <HelpBox>
          Please describe the reason to decline verification of the EPD document
          <b>
            {epd?.fullIdentificationNumber} {epd?.title}
          </b>
          The EPD owner will be informed via e-mail about the reason of declined verification.
        </HelpBox>
      }
      inputLabelText="Decline reason"
      primaryButtonText="Decline"
      isOpened={isOpened}
      onClose={onClose}
    />
  );
};

export default DeclineVerificationDialog;
