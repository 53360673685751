import { LcaResultModel } from 'types/types';
import LcaResultsGrid from './LcaResultsGrid';
import LcaResultsGridPdf from './LcaResultsGridPdf';
import { createVariationColumnWithDisclaimer, createVariationDisclaimerNote, LcaResultsGridColumnsNoCategory, LcaResultsGridColumnsNoCategoryPdf } from './LcaResultsGridColumns';

type TProps = {
  epdVersionId: string;
  rows: LcaResultModel[] | undefined;
  isForPdf?: boolean;
  disabled?: boolean;
  preferEnabledAppearanceEvenWhenDisabled?: boolean;
  isSingleProductSelected: boolean;
};

const LcaResultsGridOutputFlowConstruction = ({
  epdVersionId,
  rows,
  isForPdf,
  disabled,
  preferEnabledAppearanceEvenWhenDisabled,
  isSingleProductSelected
}: TProps) => {
  const notes: { note: React.ReactNode; description: React.ReactNode }[] = [
    {
      note: 'Acronyms',
      description:
        'CRU = Components for re-use; MFR = Materials for recycling; MER = Materials for energy recovery; EEE = Exported electrical energy; EET = Exported thermal energy.',
    },
  ];
  
  let columns = isForPdf ? LcaResultsGridColumnsNoCategoryPdf : LcaResultsGridColumnsNoCategory;
  if (!isSingleProductSelected && (!isForPdf || (isForPdf && rows?.some((r) => r.variation)))) {
    const disclaimerNumber = 1;
    columns = [...columns, createVariationColumnWithDisclaimer(disclaimerNumber)];
    notes.push(createVariationDisclaimerNote(disclaimerNumber));
  }

  if (isForPdf) {
    return <LcaResultsGridPdf rows={rows} columns={columns} notes={notes} />;
  }
  return (
    <LcaResultsGrid
      epdVersionId={epdVersionId}
      value={rows}
      columns={columns}
      notes={notes}
      disabled={disabled}
      preferEnabledAppearanceEvenWhenDisabled={preferEnabledAppearanceEvenWhenDisabled}
    />
  );
};

export default LcaResultsGridOutputFlowConstruction;
