import React from 'react';
import { useTranslation } from 'react-i18next';
import { EPDModel, EPDStatusDisplayName, EpdGroupDashboardModel } from 'services/EpdClient';
import { A, Aside, H2, Text } from 'styles/Styles.styled';

import InvoiceQuickFacts from './InvoiceQuickFacts';
import { QuickFactBox } from './styles';

type TProps = {
  allEpds: EPDModel[];
  allCopyGroups: EpdGroupDashboardModel[];
  companyId: string;
  accountId: string | undefined;
  licenseeSupportEmail: string;
};

const allEpdStatuses = Object.values(EPDStatusDisplayName);

const Sidebox = ({ allEpds, allCopyGroups, accountId, companyId, licenseeSupportEmail }: TProps) => {
  const { t } = useTranslation();

  return (
    <Aside>
      {allEpds.length > 0 && (
        <>
          <H2>{t('epdDashboard.quickStats.title')}</H2>
          <Text style={{ marginBottom: '2rem' }}>
            <QuickFactBox>
              {allEpdStatuses
                .map((status) => ({ status, value: allEpds.filter((x) => x.epdState?.statusDisplayName === status).length }))
                .filter((x) => x.value > 0)
                .map((x) => (
                  <React.Fragment key={x.status}>
                    <span>{t(`epdWizard.epdState.status.${x.status}`)}</span>
                    <strong> {x.value}</strong>
                  </React.Fragment>
                ))}

              <span>{t('epdDashboard.quickStats.copyGroups')}</span>
              <strong> {allCopyGroups.length}</strong>
            </QuickFactBox>
          </Text>
        </>
      )}

      <H2>New EPD Statuses!</H2>
      <Text style={{ textAlign: 'left' }}>
        We introduce new EPD statuses that help navigate the EPD creation process more easily. Download the{' '}
        <A href={'/EPD_statuses_guide.pdf'} target={'_blank'}>
          new statuses guide.
        </A>
        <br />
      </Text>

      <InvoiceQuickFacts accountId={accountId} companyid={companyId} />
      <H2 style={{ textTransform: 'uppercase' }}> {t('epdDashboard.faq.title')}</H2>
      <Text style={{ textAlign: 'left' }}>
        <strong>Need help with the portal? </strong>
        {'Check out our '}
        <A href={'https://environdec.com/resources/documentation#epdportalinstructions'} target={'_blank'}>
          {'Portal Manual.'}
        </A>
        <br />
        <br />
        {'You can also find answers to the most commonly asked questions about EPDs, PCRs and other subjects in our '}
        <A href={'https://environdec.com/faq'} target={'_blank'}>
          {'FAQ.'}
        </A>
      </Text>
      <br />

      <H2>{'Contact'}</H2>
      <Text style={{ textAlign: 'left' }}>
        {'For further assistance or feedback feel free to contact us per e-mail at '}
        <br /> <A href={`mailto:${licenseeSupportEmail}`}>{licenseeSupportEmail}</A>
        <br />
        <br />
      </Text>
    </Aside>
  );
};

export default Sidebox;
