import React from 'react';
import VersionHistorySection from '../VersionHistorySection';
import { useEpdVersionHistoryDataOrWhileLoadingThenNull } from '../utils/EpdVersionHistoryDataContext';
import VersionHistorySummaryGrid, { VersionHistorySummaryGridSkeleton } from '../VersionHistorySummaryGrid';
import PackagingMaterialsGridPdf from '../../grids/product-content/PackagingMaterialsGridPdf';
import { EPDContentDeclarationProductType, EPDDeclareContentProductModel } from 'types/types';
import { ISO_21930_ID } from '../../constants';
import ProductComponentsGridPdf from '../../grids/product-content/ProductComponentsGridPdf';
import PanelContentGridPdf from '../../grids/product-content/PanelContentGridPdf';
import DangerMaterialsGridPdf from '../../grids/product-content/DangerMaterialsGridPdf';

const VersionHistoryContentDeclarationSection: React.FC<{}> = () => {
  const data = useEpdVersionHistoryDataOrWhileLoadingThenNull();

  if (!data) {
    return (
      <VersionHistorySection title="Content declaration">
        <VersionHistorySummaryGridSkeleton skeletonRowsCount={4} />
        <VersionHistorySummaryGridSkeleton skeletonRowsCount={4} />
        <VersionHistorySummaryGridSkeleton skeletonRowsCount={4} />
      </VersionHistorySection>
    );
  }

  const isISO21930StandardSelected =
    data.epdDefinitionInformation.declaredStandards?.split(',').includes(ISO_21930_ID) ?? false;
  const isPanelContentEnabled =
    data.pcrInformation.cpcr?.registrationNumber?.toLocaleLowerCase()?.includes('c-pcr-014') ?? false;

  const averageProductContentDeclaration =
    data.contentDeclarationProducts.find((x) => x.type === EPDContentDeclarationProductType.Average) ?? null;
  const bestProductContentDeclaration =
    data.contentDeclarationProducts.find((x) => x.type === EPDContentDeclarationProductType.BestProduct) ?? null;
  const worstProdutContentDeclaration =
    data.contentDeclarationProducts.find((x) => x.type === EPDContentDeclarationProductType.WorstProduct) ?? null;
  const isBestCaseEnabled = !!bestProductContentDeclaration?.productDescription;
  const isWorstCaseEnabled = !!worstProdutContentDeclaration?.productDescription;

  const renderContentDeclarationSection = ({
    title,
    product,
  }: {
    title: string;
    product: EPDDeclareContentProductModel;
  }) => {
    return (
      <VersionHistorySection title={title}>
        {!!product.productDescription && (
          <VersionHistorySummaryGrid rows={[{ name: 'Content declaration of multiple products', value: product.productDescription }]} />
        )}

        <PackagingMaterialsGridPdf
          epdVersionId={data.epdVersionId}
          serviceIncludesProductInSystemBoundary={data.epdDefinitionInformation.serviceIncludesProductInSystemBoundary}
          contentDeclarationType={product.type}
          isISO21930StandardSelected={isISO21930StandardSelected}
        />

        {isPanelContentEnabled && (
          <PanelContentGridPdf
            epdVersionId={data.epdVersionId}
            contentDeclarationType={product.type}
            isISO21930StandardSelected={isISO21930StandardSelected}
          />
        )}

        <ProductComponentsGridPdf
          epdVersionId={data.epdVersionId}
          serviceIncludesProductInSystemBoundary={data.epdDefinitionInformation.serviceIncludesProductInSystemBoundary}
          contentDeclarationType={product.type}
          isISO21930StandardSelected={isISO21930StandardSelected}
        />

        {averageProductContentDeclaration?.hasDangerMaterials && (
          <DangerMaterialsGridPdf epdVersionId={data.epdVersionId} contentDeclarationType={product.type} />
        )}
      </VersionHistorySection>
    );
  };

  return (
    <>
      {averageProductContentDeclaration &&
        renderContentDeclarationSection({
          title: 'Content declaration',
          product: averageProductContentDeclaration,
        })}

      {isBestCaseEnabled &&
        renderContentDeclarationSection({
          title: 'Content declaration - Best-case product',
          product: bestProductContentDeclaration,
        })}

      {isWorstCaseEnabled &&
        renderContentDeclarationSection({
          title: 'Content declaration - Worst-case product',
          product: worstProdutContentDeclaration,
        })}
    </>
  );
};

export default VersionHistoryContentDeclarationSection;
