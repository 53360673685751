import React from 'react';
import PcrInformationPanel from './panels/pcr-information/PcrInformationPanel';
import LcaSupportPanel from './panels/lca-support/LcaSupportPanel';
import ThirdPartyVerificationPanel from './panels/third-party-verification/ThirdPartyVerificationPanel';

const PcrLcaAndVerificationTab: React.FunctionComponent<{
  epdId: string;
  epdVersionId: string;
  validationState?: any;
}> = ({ epdId, epdVersionId, validationState }) => {
  return (
    <>
      <PcrInformationPanel epdId={epdId} epdVersionId={epdVersionId} validationState={validationState} />
      <LcaSupportPanel epdId={epdId} epdVersionId={epdVersionId} validationState={validationState} />
      <ThirdPartyVerificationPanel epdId={epdId} epdVersionId={epdVersionId} validationState={validationState} />
    </>
  );
};

export default PcrLcaAndVerificationTab;
