import React from 'react';
import { useHistory } from 'react-router-dom';
import { TabPanel } from 'primereact/tabview';
import { TabViewStyled } from 'styles/v2/Styles.styled';
import { EPDDefinitionModel } from 'types/types';
import ContentDeclarationTab from './ContentDeclarationTab';
import ProductInformationTab from './ProductInformationTab';
import TechnicalPerformanceTab from './TechnicalPerformanceTab';
import { EpdLinks } from 'routes/EpdRoutes';
import { compilerStep2Tabs } from './compilerNavConstants';

const Step2: React.FunctionComponent<{
  epdId: string;
  epdVersionId: string | undefined;
  tab: string | null | undefined;
  epdDefinition: EPDDefinitionModel | undefined;
  validationState: any;
}> = ({ epdId, epdVersionId, tab, epdDefinition, validationState }) => {
  const history = useHistory();

  let tabIndex = compilerStep2Tabs.findIndex((t) => t === tab);
  if (tabIndex === -1) {
    tabIndex = 0;
  }

  return (
    <TabViewStyled
      activeIndex={tabIndex}
      onTabChange={(e) =>
        history.push(EpdLinks.epdCompilerSpecific(epdId, { step: 'step2', tab: compilerStep2Tabs[e.index] }))
      }
    >
      <TabPanel header="Product declaration">
        <ProductInformationTab
          epdId={epdId}
          epdVersionId={epdVersionId!}
          epdClassification={epdDefinition?.epdClassification}
          validationState={validationState}
        />
      </TabPanel>
      <TabPanel header="Technical performance">
        <TechnicalPerformanceTab epdVersionId={epdVersionId!} validationState={validationState} />
      </TabPanel>
      <TabPanel header="Content declaration">
        <ContentDeclarationTab
          epdId={epdId}
          epdVersionId={epdVersionId!}
          epdDefinition={epdDefinition}
          validationState={validationState}
        />
      </TabPanel>
    </TabViewStyled>
  );
};

export default Step2;
