import React from 'react';
import {
  useCopyEpdScenario,
  useCreateEpdScenario,
  useDeleteEpdScenario,
  useUpdateEpdScenario,
} from 'services/api/mutations';
import { useEpdScenarioUnits, useEpdScenarios, useEpdSystemBoundaryGrid } from 'services/api/queries';
import styled from 'styled-components';
import {
  ExpandablePanel,
  ExpandablePanelHeaderName,
  ExpandablePanelModule,
  FieldPanelFullWidth,
  FieldPanelInput,
  HeaderCaptionSemiBold,
  IconsSection,
  PanelContainer,
} from 'styles/v2/Styles.styled';
import { ScenarioItemModel } from 'types/types';

import { IconButton } from '../buttons';
import { AddCustomBlockButton } from '../buttons/AddCustomBlockButton';
import { confirmDelete } from '../confirm-dialog/ConfirmDelete';
import CompilerSelectControlledComponent from '../epd-compiler-inputs/CompilerSelectControlledComponent';
import { StyledCopyIcon } from '../epd-compiler-inputs/common-icons';
import WizardTextAreaComponent from '../epd-wizard-inputs/WizardTextArea';
import WizardTextInputComponent from '../epd-wizard-inputs/WizardTextInput';
import TooltipErrorIcon from '../icons/TooltipErrorIcon';
import TooltipHelpIcon from '../icons/TooltipHelpIcon';
import ModuleGrid from './grids/scenarios/ModuleGrid';
import useIsReadOnlyMode from './hooks/useIsReadOnlyMode';
import ToggleButton from './panels/toggleable/ToggleButton';

const ScenariosTab: React.FunctionComponent<{
  epdVersionId: string;
  validationState?: any;
}> = ({ epdVersionId, validationState }) => {
  const scenarios = useEpdScenarios(epdVersionId!).data;
  const units = useEpdScenarioUnits().data;
  const declaredModules = useEpdSystemBoundaryGrid(epdVersionId!).data;
  const createScenarioMutation = useCreateEpdScenario();
  const updateScenarioMutation = useUpdateEpdScenario();
  const deleteScenarioMutation = useDeleteEpdScenario();
  const copyScenarioMutation = useCopyEpdScenario();
  const isReadOnly = useIsReadOnlyMode();
  const scenariosErrors = (index: number) => {
    return validationState?.errors?.scenarios?.[`row[${index}]`];
  };

  const handleScenarioUpdate = (field: string, value: any, id: string) => {
    const updateScenario = scenarios?.find((item) => item.id === id);
    const newData = { ...updateScenario, [field]: value } as ScenarioItemModel;

    updateScenarioMutation.mutate(newData);
  };

  const handleCreateEpdScenario = () => {
    createScenarioMutation.mutate({ id: '00000000-0000-0000-0000-000000000000', epdVersionId, isDefaultScenario: false });
  };

  const confirmDeleteScenario = (scenario: ScenarioItemModel) => {
    const scenarioName = scenario.name ?? 'Unnamed scenario';
    confirmDelete({
      caption: `Are you sure you want to delete scenario ${scenarioName}?`,
      onConfirm: () => deleteScenarioMutation.mutate(scenario.id),
    });
  };

  const handleCopyEpdScenario = (scenarioId: string) => {
    copyScenarioMutation.mutate({ epdVersionId, id: scenarioId });
  };

  const headerTemplate = (options: any, scenario: ScenarioItemModel, scenarioNumber: number) => {
    return (
      <div className={options.className}>
        <ExpandablePanelHeaderName>
          <HeaderCaptionSemiBold>{scenario.name ?? `Unnamed scenario ${scenarioNumber}`}</HeaderCaptionSemiBold>
          <ToggleButton options={options} />
        </ExpandablePanelHeaderName>
        <IconsSection>
          {scenario.isDefaultScenario && <ScenarioDefaultBadge>Default scenario</ScenarioDefaultBadge>}
          {!isReadOnly && (
            <>
              {!scenario.isDefaultScenario && (
                <DeleteScenarioIconButton
                  icon="pi pi-trash"
                  text
                  onClick={() => {
                    confirmDeleteScenario(scenario);
                  }}
                />
              )}
              <StyledCopyIcon onClick={() => handleCopyEpdScenario(scenario.id)} />
            </>
          )}
        </IconsSection>
      </div>
    );
  };

  const headerModuleTemplate = (
    options: any,
    header: string,
    tooltip?: string,
    tooltipPosition?: any | undefined,
    required?: boolean,
    fieldNames?: string[],
    errors?: any
  ) => {
    function noErrorsAtBlock(): boolean | undefined {
      return !errors || !fieldNames?.some((field) => field in errors);
    }

    return (
      <div className={options.className}>
        <ExpandablePanelHeaderName>
          <HeaderCaptionSemiBold>{header}</HeaderCaptionSemiBold>
          {tooltip && <TooltipHelpIcon content={tooltip} position={tooltipPosition} />}
          {required && !noErrorsAtBlock() && <TooltipErrorIcon />}
          <ToggleButton options={options} />
        </ExpandablePanelHeaderName>
      </div>
    );
  };

  const rslOptions = [
    { label: 'Yes, it is included', value: true },
    { label: 'No, it is not included', value: false },
  ];

  return (
    <>
      {scenarios?.map((scenario: ScenarioItemModel, index: number) => (
        <ExpandablePanelMarginContainer key={index}>
          <ExpandablePanelMultiContainer
            key={scenario.id}
            headerTemplate={(options) => headerTemplate(options, scenario, index + 1)}
            toggleable
            collapsed={index > 0}
          >
            <FieldPanelFullWidth>
              <FieldPanelInput>
                <WizardTextInputComponent
                  label={scenario?.isDefaultScenario ? 'Default scenario name' : 'Additional scenario name'}
                  placeholder={`Unnamed scenario ${index + 1}`}
                  tooltip={
                    scenario?.isDefaultScenario
                      ? 'A descriptive name for the scenario.'
                      : 'An additional scenario for modules A4-C4, or alternative modelling approach (e.g., alternative GWP-biogenic results).'
                  }
                  name="name"
                  value={scenario.name}
                  maxLength={90}
                  required
                  error={scenariosErrors(index)?.name}
                  onChanged={(field: string, value: string) => handleScenarioUpdate(field, value, scenario.id)}
                />
              </FieldPanelInput>
              <FieldPanelInput>
                <WizardTextAreaComponent
                  label={
                    scenario?.isDefaultScenario
                      ? 'Description of the default scenario'
                      : 'Description of the additional scenario'
                  }
                  placeholder="Type here"
                  name="description"
                  tooltip={scenario?.isDefaultScenario ? 'A summary of the scenario, including key assumptions.' : ''}
                  value={scenario.description}
                  required
                  error={scenariosErrors(index)?.descriptionOfTheScenario}
                  onChanged={(field: string, value: string) => handleScenarioUpdate(field, value, scenario.id)}
                />
              </FieldPanelInput>
            </FieldPanelFullWidth>
            {declaredModules?.declaredModules?.a4 && (
              <ExpandablePanelModule
                headerTemplate={(options) =>
                  headerModuleTemplate(
                    options,
                    'Module A4: Transport to the building site',
                    undefined,
                    undefined,
                    true,
                    ['a4', 'a4SectionEmpty'],
                    scenariosErrors(index)
                  )
                }
                toggleable
              >
                <FieldPanelFullWidthNoHeaderSpacing>
                  <ModuleGrid
                    epdVersionId={epdVersionId}
                    scenarioId={scenario.id}
                    module="A4"
                    moduleName="Module A4 information"
                    unitOptions={units}
                    moduleExplanatoryNameError={scenariosErrors(index)?.a4ExplanatoryName}
                    moduleBriefDescriptionError={scenariosErrors(index)?.a4BriefDescription}
                    moduleDescriptionError={scenariosErrors(index)?.a4Desc}
                    isDefaultScenario={scenario.isDefaultScenario}
                  />
                </FieldPanelFullWidthNoHeaderSpacing>
              </ExpandablePanelModule>
            )}
            {declaredModules?.declaredModules?.a5 && (
              <ExpandablePanelModule
                headerTemplate={(options) =>
                  headerModuleTemplate(
                    options,
                    'Module A5: Installation in the building',
                    undefined,
                    undefined,
                    true,
                    ['a5', 'a5SectionEmpty'],
                    scenariosErrors(index)
                  )
                }
                toggleable
              >
                <FieldPanelFullWidthNoHeaderSpacing>
                  <ModuleGrid
                    epdVersionId={epdVersionId}
                    scenarioId={scenario.id}
                    module="A5"
                    moduleName="Module A5 information"
                    unitOptions={units}
                    moduleExplanatoryNameError={scenariosErrors(index)?.a5ExplanatoryName}
                    moduleBriefDescriptionError={scenariosErrors(index)?.a5BriefDescription}
                    moduleDescriptionError={scenariosErrors(index)?.a5Desc}
                    isDefaultScenario={scenario.isDefaultScenario}
                  />
                </FieldPanelFullWidthNoHeaderSpacing>
              </ExpandablePanelModule>
            )}
            {declaredModules?.declaredModules?.b1 && (
              <ExpandablePanelModule
                headerTemplate={(options) =>
                  headerModuleTemplate(
                    options,
                    'Module B1: Use',
                    undefined,
                    undefined,
                    true,
                    ['b1', 'b1SectionEmpty'],
                    scenariosErrors(index)
                  )
                }
                toggleable
              >
                <FieldPanelFullWidthNoHeaderSpacing>
                  <ModuleGrid
                    epdVersionId={epdVersionId}
                    scenarioId={scenario.id}
                    module="B1"
                    moduleName="Module B1 information"
                    unitOptions={units}
                    moduleExplanatoryNameError={scenariosErrors(index)?.b1ExplanatoryName}
                    moduleBriefDescriptionError={scenariosErrors(index)?.b1BriefDescription}
                    moduleDescriptionError={scenariosErrors(index)?.b1Desc}
                    isDefaultScenario={scenario.isDefaultScenario}
                  />
                </FieldPanelFullWidthNoHeaderSpacing>
              </ExpandablePanelModule>
            )}
            {declaredModules?.declaredModules?.b2 && (
              <ExpandablePanelModule
                headerTemplate={(options) =>
                  headerModuleTemplate(
                    options,
                    'Module B2: Maintenance',
                    undefined,
                    undefined,
                    true,
                    ['b2', 'b2SectionEmpty'],
                    scenariosErrors(index)
                  )
                }
                toggleable
              >
                <FieldPanelFullWidthNoHeaderSpacing>
                  <ModuleGrid
                    epdVersionId={epdVersionId}
                    scenarioId={scenario.id}
                    module="B2"
                    moduleName="Module B2 information"
                    unitOptions={units}
                    moduleExplanatoryNameError={scenariosErrors(index)?.b2ExplanatoryName}
                    moduleBriefDescriptionError={scenariosErrors(index)?.b2BriefDescription}
                    moduleDescriptionError={scenariosErrors(index)?.b2Desc}
                    isDefaultScenario={scenario.isDefaultScenario}
                  />
                </FieldPanelFullWidthNoHeaderSpacing>
              </ExpandablePanelModule>
            )}
            {declaredModules?.declaredModules?.b3 && (
              <ExpandablePanelModule
                headerTemplate={(options) =>
                  headerModuleTemplate(
                    options,
                    'Module B3: Repair',
                    undefined,
                    undefined,
                    true,
                    ['b3', 'b3SectionEmpty'],
                    scenariosErrors(index)
                  )
                }
                toggleable
              >
                <FieldPanelFullWidthNoHeaderSpacing>
                  <ModuleGrid
                    epdVersionId={epdVersionId}
                    scenarioId={scenario.id}
                    module="B3"
                    moduleName="Module B3 information"
                    unitOptions={units}
                    moduleExplanatoryNameError={scenariosErrors(index)?.b3ExplanatoryName}
                    moduleBriefDescriptionError={scenariosErrors(index)?.b3BriefDescription}
                    moduleDescriptionError={scenariosErrors(index)?.b3Desc}
                    isDefaultScenario={scenario.isDefaultScenario}
                  />
                </FieldPanelFullWidthNoHeaderSpacing>
              </ExpandablePanelModule>
            )}
            {declaredModules?.declaredModules?.b4 && (
              <ExpandablePanelModule
                headerTemplate={(options) =>
                  headerModuleTemplate(
                    options,
                    'Module B4: Replacement',
                    undefined,
                    undefined,
                    true,
                    ['b4', 'b4SectionEmpty'],
                    scenariosErrors(index)
                  )
                }
                toggleable
              >
                <FieldPanelFullWidthNoHeaderSpacing>
                  <ModuleGrid
                    epdVersionId={epdVersionId}
                    scenarioId={scenario.id}
                    module="B4"
                    moduleName="Module B4 information"
                    unitOptions={units}
                    moduleExplanatoryNameError={scenariosErrors(index)?.b4ExplanatoryName}
                    moduleBriefDescriptionError={scenariosErrors(index)?.b4BriefDescription}
                    moduleDescriptionError={scenariosErrors(index)?.b4Desc}
                    isDefaultScenario={scenario.isDefaultScenario}
                  />
                </FieldPanelFullWidthNoHeaderSpacing>
              </ExpandablePanelModule>
            )}
            {declaredModules?.declaredModules?.b5 && (
              <ExpandablePanelModule
                headerTemplate={(options) =>
                  headerModuleTemplate(
                    options,
                    'Module B5: Refurbishment',
                    undefined,
                    undefined,
                    true,
                    ['b5', 'b5SectionEmpty'],
                    scenariosErrors(index)
                  )
                }
                toggleable
              >
                <FieldPanelFullWidthNoHeaderSpacing>
                  <ModuleGrid
                    epdVersionId={epdVersionId}
                    scenarioId={scenario.id}
                    module="B5"
                    moduleName="Module B5 information"
                    unitOptions={units}
                    moduleExplanatoryNameError={scenariosErrors(index)?.b5ExplanatoryName}
                    moduleBriefDescriptionError={scenariosErrors(index)?.b5BriefDescription}
                    moduleDescriptionError={scenariosErrors(index)?.b5Desc}
                    isDefaultScenario={scenario.isDefaultScenario}
                  />
                </FieldPanelFullWidthNoHeaderSpacing>
              </ExpandablePanelModule>
            )}
            {declaredModules?.declaredModules?.b6 && (
              <ExpandablePanelModule
                headerTemplate={(options) =>
                  headerModuleTemplate(
                    options,
                    'Module B6: Operational energy use',
                    undefined,
                    undefined,
                    true,
                    ['b6', 'b6SectionEmpty'],
                    scenariosErrors(index)
                  )
                }
                toggleable
              >
                <FieldPanelFullWidthNoHeaderSpacing>
                  <ModuleGrid
                    epdVersionId={epdVersionId}
                    scenarioId={scenario.id}
                    module="B6"
                    moduleName="Module B6 information"
                    unitOptions={units}
                    moduleExplanatoryNameError={scenariosErrors(index)?.b6ExplanatoryName}
                    moduleBriefDescriptionError={scenariosErrors(index)?.b6BriefDescription}
                    moduleDescriptionError={scenariosErrors(index)?.b6Desc}
                    isDefaultScenario={scenario.isDefaultScenario}
                  />
                </FieldPanelFullWidthNoHeaderSpacing>
              </ExpandablePanelModule>
            )}
            {declaredModules?.declaredModules?.b7 && (
              <ExpandablePanelModule
                headerTemplate={(options) =>
                  headerModuleTemplate(
                    options,
                    'Module B7: Operational water use',
                    undefined,
                    undefined,
                    true,
                    ['b7', 'b7SectionEmpty'],
                    scenariosErrors(index)
                  )
                }
                toggleable
              >
                <FieldPanelFullWidthNoHeaderSpacing>
                  <ModuleGrid
                    epdVersionId={epdVersionId}
                    scenarioId={scenario.id}
                    module="B7"
                    moduleName="Module B7 information"
                    unitOptions={units}
                    moduleExplanatoryNameError={scenariosErrors(index)?.b7ExplanatoryName}
                    moduleBriefDescriptionError={scenariosErrors(index)?.b7BriefDescription}
                    moduleDescriptionError={scenariosErrors(index)?.b7Desc}
                    isDefaultScenario={scenario.isDefaultScenario}
                  />
                </FieldPanelFullWidthNoHeaderSpacing>
              </ExpandablePanelModule>
            )}

            {(declaredModules?.declaredModules?.c1 ||
              declaredModules?.declaredModules?.c2 ||
              declaredModules?.declaredModules?.c3 ||
              declaredModules?.declaredModules?.c4) && (
              <ExpandablePanelModule
                headerTemplate={(options) =>
                  headerModuleTemplate(
                    options,
                    'Module C: End-of-life',
                    undefined,
                    undefined,
                    true,
                    ['c', 'cSectionEmpty'],
                    scenariosErrors(index)
                  )
                }
                toggleable
              >
                <FieldPanelFullWidthNoHeaderSpacing>
                  <ModuleGrid
                    epdVersionId={epdVersionId}
                    scenarioId={scenario.id}
                    module="C1"
                    commonModule="C"
                    moduleName="Module C information"
                    unitOptions={units}
                    moduleExplanatoryNameError={scenariosErrors(index)?.cExplanatoryName}
                    moduleBriefDescriptionError={scenariosErrors(index)?.cBriefDescription}
                    moduleDescriptionError={scenariosErrors(index)?.cDesc}
                    isDefaultScenario={scenario.isDefaultScenario}
                  />
                </FieldPanelFullWidthNoHeaderSpacing>
              </ExpandablePanelModule>
            )}

            <ExpandablePanelModule
              headerTemplate={(options) =>
                headerModuleTemplate(
                  options,
                  'Reference service life',
                  'The RSL of a product category is the reference time to which the performance of all products of a product category shall be related.',
                  'top | right',
                  true,
                  ['rsl', 'rslSectionEmpty'],
                  scenariosErrors(index)
                )
              }
              toggleable
            >
              <FieldPanelFullWidthNoHeaderSpacing>
                <FieldPanelInput>
                  <CompilerSelectControlledComponent
                    label="Is RSL included in the EPD"
                    error={scenariosErrors(index)?.enabledRSL}
                    placeholder="Select"
                    options={rslOptions}
                    value={rslOptions.find((o) => o.value === scenario.enabledRSL)}
                    name="enabledRSL"
                    onChanged={(field: string, value: boolean) => handleScenarioUpdate(field, value, scenario.id)}
                  />
                </FieldPanelInput>
                {scenario.enabledRSL && (
                  <ModuleGrid
                    epdVersionId={epdVersionId}
                    scenarioId={scenario.id}
                    module="RSL"
                    moduleName="Reference service life information"
                    unitOptions={units}
                    moduleDescriptionError={scenariosErrors(index)?.rslDesc}
                    isDefaultScenario={scenario.isDefaultScenario}
                  />
                )}
              </FieldPanelFullWidthNoHeaderSpacing>
            </ExpandablePanelModule>

            {declaredModules?.declaredModules?.d && (
              <ExpandablePanelModule
                headerTemplate={(options) =>
                  headerModuleTemplate(
                    options,
                    'Module D: Beyond product life cycle',
                    undefined,
                    undefined,
                    true,
                    ['d', 'dSectionEmpty'],
                    scenariosErrors(index)
                  )
                }
                toggleable
              >
                <FieldPanelFullWidthNoHeaderSpacing>
                  <ModuleGrid
                    epdVersionId={epdVersionId}
                    scenarioId={scenario.id}
                    module="D"
                    moduleName="Module D information"
                    unitOptions={units}
                    moduleExplanatoryNameError={scenariosErrors(index)?.dExplanatoryName}
                    moduleBriefDescriptionError={scenariosErrors(index)?.dBriefDescription}
                    moduleDescriptionError={scenariosErrors(index)?.dDesc}
                    isDefaultScenario={scenario.isDefaultScenario}
                  />
                </FieldPanelFullWidthNoHeaderSpacing>
              </ExpandablePanelModule>
            )}
          </ExpandablePanelMultiContainer>
        </ExpandablePanelMarginContainer>
      ))}

      {!isReadOnly && <AddCustomBlockButton onClick={handleCreateEpdScenario} caption="Add additional scenario" />}
    </>
  );
};

const FieldPanelFullWidthNoHeaderSpacing = styled(FieldPanelFullWidth)`
  margin-top: -20px;
`;

const ScenarioDefaultBadge = styled.div`
  ${(props) => props.theme.typography.headerCaptionMedium};
  white-space: nowrap;
  height: 22px;
  padding: 0.25rem 0.75rem;
  border-radius: 2px;
  color: ${(props) => props.theme.colors.statusLabelGreenText};
  background-color: ${(props) => props.theme.colors.statusLabelGreenBg};
`;

const ExpandablePanelMarginContainer = styled(PanelContainer)`
  box-shadow: none;
`;

const ExpandablePanelMultiContainer = styled(ExpandablePanel)`
  .p-panel-content {
    padding: 0;
    border: 0;
    background-color: transparent;
    width: 100%;
    display: grid;
  }
`;

const DeleteScenarioIconButton = styled(IconButton)`
  width: 16px !important;
  height: 16px !important;
  &.p-button.p-button-text {
    color: ${(props) => props.theme.colors.primaryBlack};
  }
  &.p-button.p-button-text:hover {
    background: transparent;
  }
`;

export default ScenariosTab;
