import { CompanyContext } from 'contexts/CompanyContextProvider';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionState } from 'services/EpdClient';
import styled, { useTheme } from 'styled-components';
import { A, HyperLink } from 'styles/Styles.styled';
import { Theme } from 'styles/theme';
import { ActionFieldPanelFullWidth, CheckBoxPanel, FieldPanelFullWidth, FieldPanelHeader } from 'styles/v2/Styles.styled';

import { useEPDDefinitionInformation, useEpdOrganizationInformation } from 'services/api/queries';
import DatePicker from '../copy/DatePicker';
import WizardCheckbox from '../epd-wizard-inputs/WizardCheckbox';
import WizardTextInputComponent from '../epd-wizard-inputs/WizardTextInput';
import { HelpBox } from '../help-boxes';
import { useUpdateEpdGeneralInformation } from 'services/api/mutations';

const Step5: React.FunctionComponent<{
  epdId: string;
  epdVersionId: string;
  validationState: any;
}> = ({ epdId, epdVersionId, validationState }) => {
  const organizationInformation = useEpdOrganizationInformation(epdVersionId).data;
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const { company } = useContext(CompanyContext);
  const epdDefinition = useEPDDefinitionInformation(epdVersionId).data;
  const registrationDate = epdDefinition?.registrationDate ? new Date(epdDefinition.registrationDate) : undefined;
  const publicVersionDate = epdDefinition?.publicVersionDate ? new Date(epdDefinition.publicVersionDate) : undefined;
  const updateEpdGeneralInformation = useUpdateEpdGeneralInformation(epdVersionId);

  const handleChangeProperty = (propertyName: string, newValue: any) => {
    updateEpdGeneralInformation.mutate({
      epdId,
      versionId: epdVersionId,
      propertyName,
      newValue,
    });
  };

  return (
    <FieldPanelsContainer>
      <ActionFieldPanelFullWidth
        style={{ marginTop: 0 }}
        actionState={validationState?.fieldsState?.registrationDate ?? ActionState.Hidden}
      >
        <DatePicker
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const inputDate = new Date(e.target.value);
            const utcDate = new Date(Date.UTC(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate()));
            handleChangeProperty('registrationDate', utcDate.toISOString());
          }}
          dateFormat={'yy-mm-dd'}
          label={'Initial version date'}
          initialDate={registrationDate}
          tooltip={'Exact date of the publication of the EPD'}
          required={validationState?.errors?.registrationDate}
          disabled={validationState?.fieldsState?.registrationDate === ActionState.Disabled}
          error={validationState?.errors?.registrationDate}
        />
      </ActionFieldPanelFullWidth>
      <ActionFieldPanelFullWidth actionState={validationState?.fieldsState?.publicVersionDate ?? ActionState.Hidden}>
        <DatePicker
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const inputDate = new Date(e.target.value);
            const utcDate = new Date(Date.UTC(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate()));
            handleChangeProperty('publicVersionDate', utcDate.toISOString());
          }}
          dateFormat={'yy-mm-dd'}
          label={'Revision date'}
          initialDate={publicVersionDate}
          tooltip={'Date when the data set was revised for the last time; typically manually set.'}
          required={validationState?.errors?.publicVersionDate}
          disabled={validationState?.fieldsState?.publicVersionDate === ActionState.Disabled}
          error={validationState?.errors?.publicVersionDate}
        />
      </ActionFieldPanelFullWidth>
      <ActionFieldPanelFullWidth actionState={validationState?.fieldsState?.confirmationBlock ?? ActionState.Hidden}>
        <FieldPanelHeader>Confirmation for publication</FieldPanelHeader>
        <HelpBox>
          <span>EPD owner should accept the service agreement and submit the EPD for publication. </span>
        </HelpBox>
        <div>
          <ContentWrapper>
            <p>
              As an authorized person of the EPD owner, the EPD owner hereby requests to register and publish the EPD in the
              International EPD System, and confirms the accuracy and completeness of the attached documentation and its
              contents, and hereby undertake to observe the terms and conditions stated in the Service Agreement including
              its appendix, and the from time to time applicable General Programme Instructions, and the applicable PCR for
              the EPD included in this Agreement.
            </p>
            <p>
              The EPD owner agrees that name and e-mail address provided above and in the submitted EPD may be stored
              electronically, may be used as contact information regarding the EPD and the services under this Agreement, and
              that the data displayed in the EPD will be published at{' '}
              <HyperLink target="_blank" rel="noopener noreferrer" href="https://www.environdec.com">
                www.environdec.com
              </HyperLink>
              .
              <br />
              EPDs using the ECO Platform logo and machine-readable EPD files will also be published on other relevant
              platforms.
            </p>
            <p>
              <strong>{t('epdWizard.wizardStep4.properties.confirmation.infoFees')}</strong>
            </p>
            <ServiceAgreementArea href={theme.serviceAgreementUrl}>
              <i className="pi pi-external-link" style={{ fontSize: '0.75rem', color: 'White', marginRight: '0.5rem' }}></i>
              <p style={{ color: 'White', margin: 0 }}>Service agreement</p>
            </ServiceAgreementArea>
          </ContentWrapper>
          <CheckBoxPanel>
            <WizardCheckbox
              inputId="serviceAgreementStatus"
              label={'I have read and accept the service agreement'}
              name={'serviceAgreementStatus'}
              value={epdDefinition?.serviceAgreementStatus as boolean}
              onChanged={handleChangeProperty}
            />
          </CheckBoxPanel>
        </div>
      </ActionFieldPanelFullWidth>
      <FieldPanelFullWidth>
        <WizardTextInputComponent label="EPD owner" name="companyName" disabled value={company?.name} />
        <WizardTextInputComponent
          label="Contact e-mail"
          name="contactEmail"
          disabled
          value={organizationInformation?.contactPersonEmail}
        />
        <WizardTextInputComponent
          label="Contact person name"
          name="contactPerson"
          disabled
          value={organizationInformation?.contactPersonName}
        />
        <WizardTextInputComponent label="Country" name="country" disabled value={company?.address?.country} />
        <WizardTextInputComponent label="Copyright" disabled name="copyright" value={'Yes'} />
        <WizardTextInputComponent
          label="License type"
          disabled
          name="licenseType"
          value={'Free of charge for all users and uses'}
        />
      </FieldPanelFullWidth>
    </FieldPanelsContainer>
  );
};

const FieldPanelsContainer = styled.div`
  &&& > div:not([hidden]):not([style*='display: none']):first-of-type {
    margin-top: 0 !important;
  }
`;

const ContentWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.elementsInputBorder};
  padding: 1rem 1.5rem 1rem 1rem;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;

  p {
    margin-top: 0;
  }
`;

const ServiceAgreementArea = styled(A)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 160px;
  height: 2.5vh;
  background-color: ${(props) => props.theme.colors.primaryOrange};
  border-radius: 2px;
  &:hover {
    background-color: ${(props) => props.theme.colors.lightOrange};
  }
`;

export default Step5;
