import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { StatusApprovedIcon, StatusAttentionIcon } from '../icons';

const StepPanel: React.FunctionComponent<{
  label: string;
  name: string;
  description: string;
  isSelected: boolean;
  isValid: ((item: any) => boolean | undefined) | boolean | undefined;
  path: string,
}> = ({ label, name, description, isSelected, isValid, path }) => {
  return (
    <Container to={path} $isSelected={isSelected}>
      {isValid === true && <StatusApprovedIcon style={{ position: 'absolute', top: '12px', right: '12px' }} />}
      {isValid === false && <StatusAttentionIcon style={{ position: 'absolute', top: '12px', right: '12px' }} />}

      <StepLabel>{label}</StepLabel>
      <StepName isSelected={isSelected}>{name}</StepName>
      <StepDescription>{description}</StepDescription>
    </Container>
  );
};

const StepLabel = styled.div`
  ${(props) => props.theme.typography.headerCaptionRegular};
  color: ${(props) => props.theme.colors.primaryBlack70};
`;

const StepName = styled.div<{ isSelected?: boolean }>`
  ${(props) => props.theme.typography.headerH4};
  padding-top: 10px;
  color: ${(props) => (props.isSelected ? props.theme.colors.primaryGreen : props.theme.colors.primaryBlack)};
`;

const StepDescription = styled.div`
  ${(props) => props.theme.typography.bodyInput};
  color: ${(props) => props.theme.colors.primaryBlack70};
`;

const Container = styled(Link)<{ $isSelected?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
  padding: 16px;
  background-color: ${(props) => (props.$isSelected ? props.theme.colors.primaryWhite : '#FAFAFB')};
  text-decoration: none;
  &:hover {
    background-color: ${(props) => (props.$isSelected ? props.theme.colors.primaryWhite : props.theme.colors.lightGray)};
  }
  position: relative;
`;

export default StepPanel;
