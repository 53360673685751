import { useTranslation } from 'react-i18next';
import { EpdLinks } from 'routes/EpdRoutes';
import { EPDModel } from 'services/EpdClient';
import styled from 'styled-components';
import { formatDate } from 'util/utils';

import { Card, CardHeading, CardName, CardSmallText, CardStatus } from './styles';

type TProps = {
  epd: EPDModel;
  background: any;
};

const EpdCard = ({ epd, background }: TProps) => {
  const { t } = useTranslation();
  return (
    <Card to={EpdLinks.epd(epd.id)} background={background}>
      <div>
        <CardHeading>{epd.fullIdentificationNumber}</CardHeading>
        <CardName>{epd.title}</CardName>
      </div>
      <div>
        <CardSmallText>{epd?.isCompilerFormat ? 'Digital EPD format' : 'Standard EPD format'}</CardSmallText>
        <CardSmallText>
          {epd?.publicVersionDate && !epd.publicVersionDate.toString().startsWith('1900')
            ? `Published: ${formatDate(epd?.publicVersionDate)}`
            : `Created: ${formatDate(epd?.createdAt)}`}
        </CardSmallText>
        {epd.epdState && (
          <CardStatus status={epd.epdState.statusDisplayName}>
            {t(`epdWizard.epdState.status.${epd.epdState.statusDisplayName}`)}
          </CardStatus>
        )}
      </div>
    </Card>
  );
};

export default EpdCard;
