import { LcaResultModel } from 'types/types';
import LcaResultsGrid from './LcaResultsGrid';
import LcaResultsGridPdf from './LcaResultsGridPdf';
import { createVariationColumnWithDisclaimer, createVariationDisclaimerNote, LcaResultsGridColumnsNoCategory, LcaResultsGridColumnsNoCategoryPdf } from './LcaResultsGridColumns';

type TProps = {
  epdVersionId: string;
  rows: LcaResultModel[] | undefined;
  isForPdf?: boolean;
  disabled?: boolean;
  preferEnabledAppearanceEvenWhenDisabled?: boolean;
  isSingleProductSelected: boolean;
};

const LcaResultsGridResourceConstruction = ({
  epdVersionId,
  rows,
  isForPdf,
  disabled,
  preferEnabledAppearanceEvenWhenDisabled,
  isSingleProductSelected
}: TProps) => {
  const notes: { note: React.ReactNode; description: React.ReactNode }[] = [
    {
      note: 'Acronyms',
      description:
        'PERE = Use of renewable primary energy excluding renewable primary energy resources used as raw materials; PERM = Use of renewable primary energy resources used as raw materials; PERT = Total use of renewable primary energy resources; PENRE = Use of non-renewable primary energy excluding non-renewable primary energy resources used as raw materials; PENRM = Use of non-renewable primary energy resources used as raw materials; PENRT = Total use of non-renewable primary energy re-sources; SM = Use of secondary material; RSF = Use of renewable secondary fuels; NRSF = Use of non-renewable secondary fuels; FW = Use of net fresh water.',
    },
  ];

  let columns = isForPdf ? LcaResultsGridColumnsNoCategoryPdf : LcaResultsGridColumnsNoCategory;
  if (!isSingleProductSelected && (!isForPdf || (isForPdf && rows?.some((r) => r.variation)))) {
    const disclaimerNumber = 1;
    columns = [...columns, createVariationColumnWithDisclaimer(disclaimerNumber)];
    notes.push(createVariationDisclaimerNote(disclaimerNumber));
  }

  if (isForPdf) {
    return <LcaResultsGridPdf rows={rows} columns={columns} notes={notes} />;
  }
  return (
    <LcaResultsGrid
      epdVersionId={epdVersionId}
      value={rows}
      columns={columns}
      notes={notes}
      disabled={disabled}
      preferEnabledAppearanceEvenWhenDisabled={preferEnabledAppearanceEvenWhenDisabled}
    />
  );
};

export default LcaResultsGridResourceConstruction;
