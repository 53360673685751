import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import { useEpdScenarioModule, useEpdScenarioUnits } from 'services/api/queries';
import styled, { css } from 'styled-components';
import { ScenarioModuleItemModel, ScenarioModuleRequest } from 'types/types';
import { HeaderCaptionSemiBold } from 'styles/v2/Styles.styled';

import { DataTableStyled, Placeholder } from '../StyledGrid';
import { PDF_Table } from 'pages/v2/compiler/PDF/Styles.styled';

type TProps = {
  epdVersionId: string;
  scenarioId: string;
  module: string;
  moduleName: string;
  gridCaption?: string;
  isDefaultScenario: boolean;
};

const ModuleGridPdf = ({ epdVersionId, scenarioId, module, moduleName, gridCaption, isDefaultScenario }: TProps) => {
  const { t } = useTranslation();

  const scenarioModule = useEpdScenarioModule({ epdVersionId, scenarioId, module } as ScenarioModuleRequest).data;
  const units = useEpdScenarioUnits().data;

  const header = gridCaption ? <HeaderCaptionSemiBold>{gridCaption}</HeaderCaptionSemiBold> : undefined;

  const descriptionBodyTemplate = (rowData: ScenarioModuleItemModel) => {
    return rowData?.mandatory ? (
      <DescriptionCell
        readonly={true}
        dangerouslySetInnerHTML={{
          __html: t(`epdCompiler.step3.scenarioTab.epdScenarioModule.${module}.description.${rowData.placeholder}`),
        }}
      />
    ) : (
      <DescriptionCell readonly={false}>
        {rowData?.placeholder && !rowData?.description ? (
          <Placeholder>
            {t(`epdCompiler.step3.scenarioTab.epdScenarioModule.${module}.description.${rowData.placeholder}`)}
          </Placeholder>
        ) : (
          rowData?.description ?? <Placeholder>Type here</Placeholder>
        )}
      </DescriptionCell>
    );
  };

  const valueBodyTemplate = (rowData: ScenarioModuleItemModel) => {
    return rowData?.value ? (
      rowData.value
    ) : rowData.placeholder ? (
      <Placeholder>
        {t(`epdCompiler.step3.scenarioTab.epdScenarioModule.${module}.value.${rowData.placeholder}`)}
      </Placeholder>
    ) : (
      <Placeholder>Type value as appropriate</Placeholder>
    );
  };

  const unitBodyTemplate = (rowData: ScenarioModuleItemModel) => {
    const option = units?.find((x) => x.value === rowData.unitId);
    return option && option !== null && <div dangerouslySetInnerHTML={{ __html: option.label }} />;
  };

  return (
    <>
      <PDF_Table style={{ marginBottom: 15 }}>
        <thead>
          <tr>
            <th style={{ width: '30%' }}></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isDefaultScenario && module !== 'RSL' && (
            <>
              <tr>
                <td style={{ fontSize: '12px', padding: '15px' }}>
                  Explanatory name of the default scenario in module {module}
                </td>
                <td style={{ fontSize: '12px', padding: '15px' }}>{scenarioModule?.defaultScenarioModuleExplanatoryName}</td>
              </tr>
              <tr>
                <td style={{ fontSize: '12px', padding: '15px' }}>
                  Brief description of the default scenario in module {module}
                </td>
                <td style={{ fontSize: '12px', padding: '15px' }}>
                  {scenarioModule?.defaultScenarioModuleBriefDescription}
                </td>
              </tr>
            </>
          )}
          {scenarioModule?.defaultScenarioModuleDescription?.trim() && (
              <tr>
                <td style={{ fontSize: '12px', padding: '15px' }}>
                  Description of the {isDefaultScenario ? 'default' : 'additional'} scenario in module {module}
                </td>
                <td style={{ fontSize: '12px', padding: '15px' }}>{scenarioModule?.defaultScenarioModuleDescription}</td>
              </tr>
            )}
        </tbody>
      </PDF_Table>
        <ModuleDataTableStyled value={scenarioModule?.scenarioModuleItems} dataKey="id" header={header} showGridlines>
          <Column field="description" header={moduleName} body={descriptionBodyTemplate} style={{ width: '40%' }} />
          <Column field="value" header="Value" body={valueBodyTemplate} className="padding" style={{ width: '40%' }} />
          <Column field="unitId" header="Unit" body={unitBodyTemplate} className="padding" style={{ width: '40%' }} />
        </ModuleDataTableStyled>
    </>
  );
};

const ModuleDataTableStyled = styled(DataTableStyled)`
  border-collapse: collapse;
  .p-datatable-table {
    height: 100%;
  }
  .p-datatable-tbody > tr > td {
    padding: 0;
  }
  .p-datatable-tbody > tr > td.padding,
  .p-datatable-tbody > tr.p-datatable-emptymessage > td {
    padding: 0.5rem 1rem;
  }
  && .p-inputtext.p-dropdown-label.p-placeholder {
    color: ${(props) => props.theme.colors.primaryBlack};
  }
  .p-checkbox.p-component.p-disabled > .p-checkbox-box.p-component {
    opacity: 1;
    border-color: ${(props) => props.theme.colors.elementsDropDown};
  }
`;

const CellContainer = styled.div<{ readonly: boolean }>`
  ${(props) =>
    props.readonly &&
    css`
      background-color: ${(props) => props.theme.colors.elementsFilledInput};
    `}
  padding: 0.5rem 1rem;
  height: 100%;
`;

const DescriptionCell = styled(CellContainer)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default ModuleGridPdf;
