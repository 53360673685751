import { BaseQueryParams, EPDContentDeclarationProductType, QRCodeTypeEnum, ScenarioModuleRequest } from 'types/types';

import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from './constants';
import {
  getCompanyCollectionsLookup,
  getCompanyUsersLookup,
  getCompanyVerifiers,
  getConversionFactor,
  getCountriesLookup,
  getCpcCodes,
  getCpcrSpecificData,
  getDangerMaterialsLookup,
  getDictionaryCatalog,
  getDictionaryValues,
  getDocumentTypes,
  getEPDDefinitionModel,
  getElectricity,
  getEpdLatestVersionId,
  getCompilerCompositeStatus,
  getEpdAdditionalInformation,
  getEpdCO2UptakeAssociation,
  getEpdContentDeclarationProducts,
  getEpdCoverPageInformation,
  getEpdDataQualityAssessmentAndReferenceYears,
  getEpdDataSourceOptions,
  getEpdDataSources,
  getEpdDeclarationsOfCO2,
  getEpdFormat,
  getEpdLcaSupport,
  getEpdOrganizationInformation,
  getEpdOrganizationInformationAddress,
  getEpdPCRTypeInformation,
  getEpdProductComponents,
  getEpdProductDangerMaterials,
  getEpdProductInformation,
  getEpdProductPackagingMaterials,
  getEpdProductPanelContent,
  getEpdScenarioModule,
  getEpdScenarioUnits,
  getEpdScenarios,
  getEpdScrapInputs,
  getEpdShareOfTotalScrapInput,
  getEpdSystemBoundary,
  getEpdSystemBoundaryGrid,
  getEpdThirdPartyVerification,
  getEpdTransportationPerformance,
  getEpdVerificationReportInfo,
  getGeographicalScopeLookup,
  getInfrastructureAndCapitalGoods,
  getLCASoftwareOptions,
  getLCASpecification,
  getLanguages,
  getMaterialPropertiesLookup,
  getQRCode,
  getQRCodeForPdf,
  getReferenceFlow,
  getReferencePackageVersions15804,
  getTags,
  getEpdAdditionalDocuments,
} from './epdApi';
import {
  getEpdLcaCustomResultGridData,
  getEpdLcaCustomResults,
  getEpdLcaResults,
  getEpdLcaResultsAdditional,
  getEpdLcaResultsAdditionalAcronyms,
  parseAndSaveLCAFile,
} from './epdLcaResultsApi';
import EPDService from 'services/EpdService';

const baseQuery = <TData, TError>(
  versionId: string,
  queryKey: string[],
  queryFn: (versionId: string, params: BaseQueryParams) => Promise<TData>
) => {
  return useQuery({
    queryKey: queryKey.includes(versionId) ? queryKey : [...queryKey, versionId],
    queryFn: ({ signal }) => queryFn(versionId, { signal }),
    refetchOnWindowFocus: false,
    enabled: !!versionId,
  });
};

export const useAreQueriesComplete = (queries: UseQueryResult<unknown, Error>[]): boolean => {
  return queries.every((query) => !query.isLoading && !query.isFetching);
};

export const useDictionaryCatalog = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_DICTIONARY_CATALOG],
    queryFn: () => getDictionaryCatalog(),
    refetchOnWindowFocus: false,
  });
};

export const useDictionaryValues = (dictionaryCode?: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_DICTIONARY_VALUES, dictionaryCode],
    queryFn: () => getDictionaryValues(dictionaryCode),
    refetchOnWindowFocus: false,
  });
};

export const useEpdCollectionsLookup = (companyId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_COLLECTIONS_LOOKUP, companyId],
    queryFn: () => getCompanyCollectionsLookup(companyId),
    refetchOnWindowFocus: false,
    enabled: !!companyId,
  });
};

export const useCompanyUsersLookup = (companyId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_COMPANY_USERS_LOOKUP, companyId],
    queryFn: () => getCompanyUsersLookup(companyId),
    refetchOnWindowFocus: false,
    enabled: !!companyId,
  });
};

export const useEpdLatestVersionId = (epdId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_LATEST_VERSION, epdId],
    queryFn: ({ signal }) => getEpdLatestVersionId(epdId, { signal }),
    refetchOnWindowFocus: false,
    enabled: !!epdId,
  });
};

export const useEpdsListDashboard = (companyId: string | undefined, accountId: string | undefined) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPDS_LIST_DASHBOARD, companyId, accountId],
    queryFn: () => EPDService.getEpds(accountId!, companyId),
    enabled: !!companyId && !!accountId,
    staleTime: 0, // Override global setting so we don't need to invalidate this query
  });
};

export const useEpdsGroupsListDashboard = (companyId: string | undefined, accountId: string | undefined) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPDS_GROUPS_LIST_DASHBOARD, companyId, accountId],
    queryFn: () => EPDService.getEpdGroups(accountId!, companyId),
    enabled: !!companyId && !!accountId,
    staleTime: 0, // Override global setting so we don't need to invalidate this query
  });
};

export const useEpdFormat = (epdId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_FORMAT, epdId],
    queryFn: () => getEpdFormat(epdId),
    refetchOnWindowFocus: false,
    enabled: !!epdId,
  });
};

export const useEPDDefinitionInformation = (versionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_DEFINITION, versionId],
    queryFn: ({ signal }) => getEPDDefinitionModel(versionId, { signal }),
    refetchOnWindowFocus: false,
    enabled: !!versionId,
  });
};

export const useCompilerCompositeStatus = (epdId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES, epdId],
    queryFn: ({ signal }) => getCompilerCompositeStatus(epdId, { signal }),
    refetchOnWindowFocus: false,
    enabled: !!epdId,
  });
};

export const useEpdVerificationReportInfo = (versionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_VERIFICATION_REPORT_INFO, versionId],
    queryFn: () => getEpdVerificationReportInfo(versionId),
    refetchOnWindowFocus: false,
    enabled: !!versionId,
  });
};

export const useLanguages = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.LANGUAGES],
    queryFn: () => getLanguages(),
    refetchOnWindowFocus: false,
  });
};

export const useDocumentTypes = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.DOCUMENT_TYPES],
    queryFn: () => getDocumentTypes(),
    refetchOnWindowFocus: false,
  });
};

export const useEpdOrganizationInformation = (versionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_ORGANIZATION_INFORMATION, versionId],
    queryFn: () => getEpdOrganizationInformation(versionId),
    refetchOnWindowFocus: false,
    enabled: !!versionId,
  });
};

export const useEpdAdditionalInformation = (versionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_ADDITIONAL_INFORMATION, versionId],
    queryFn: () => getEpdAdditionalInformation(versionId),
    refetchOnWindowFocus: false,
    enabled: !!versionId,
  });
};

export const useEpdAdditionalDocuments = (versionId: string) => {
  return baseQuery(versionId, [QUERY_KEYS.EPD_ADDITIONAL_DOCUMENTS, versionId], getEpdAdditionalDocuments);
};

export const useEpdProductInformation = (versionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_PRODUCT_INFORMATION, versionId],
    queryFn: () => getEpdProductInformation(versionId),
    refetchOnWindowFocus: false,
    enabled: !!versionId,
  });
};

export const useUnCpcCodes = (data: any) => {
  return useQuery({
    queryKey: [QUERY_KEYS.UN_CPC_CODES],
    queryFn: () => getCpcCodes(data),
    refetchOnWindowFocus: false,
  });
};

export const useEpdCoverPageInformation = (versionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_COVER_PAGE_INFORMATION, versionId],
    queryFn: () => getEpdCoverPageInformation(versionId),
    refetchOnWindowFocus: false,
    enabled: !!versionId,
  });
};

export const useGetEpdPCRInformation = (versionId: string) => {
  return baseQuery(versionId, [QUERY_KEYS.EPD_PCR_INFORMATION, versionId], getEpdPCRTypeInformation);
};

export const useGetEpdLcaSupport = (versionId: string) => {
  return baseQuery(versionId, [QUERY_KEYS.EPD_LCA_SUPPORT, versionId], getEpdLcaSupport);
};

export const useGetEpdThirdPartyVerification = (versionId: string) => {
  return baseQuery(versionId, [QUERY_KEYS.EPD_THIRD_PARTY_VERIFICATION, versionId], getEpdThirdPartyVerification);
};

export const useEpdOrganizationInformationAddress = (versionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_ORGANIZATION_INFORMATION_ADDRESS, versionId],
    queryFn: () => getEpdOrganizationInformationAddress(versionId),
    refetchOnWindowFocus: false,
    enabled: !!versionId,
  });
};

export const useTagSource = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.TAG_SOURCE],
    queryFn: () => getTags(),
    refetchOnWindowFocus: false,
  });
};

export const useEpdSystemBoundaryData = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_SYSTEM_BOUNDARY, epdVersionId],
    queryFn: () => getEpdSystemBoundary(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdContentDeclarationProducts = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.BEST_AND_WORST_PRODUCT, epdVersionId],
    queryFn: () => getEpdContentDeclarationProducts(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdProductComponents = (epdVersionId: string, type: EPDContentDeclarationProductType) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_PRODUCT_COMPONENTS, epdVersionId, type],
    queryFn: () => getEpdProductComponents(epdVersionId, type),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdProductPackagingMaterials = (epdVersionId: string, type: EPDContentDeclarationProductType) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_PRODUCT_PACKAGING_MATERIALS, epdVersionId, type],
    queryFn: () => getEpdProductPackagingMaterials(epdVersionId, type),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdProductPanelContent = (epdVersionId: string, type: EPDContentDeclarationProductType) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_PRODUCT_PANEL_CONTENT, epdVersionId, type],
    queryFn: () => getEpdProductPanelContent(epdVersionId, type),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdProductDangerMaterials = (epdVersionId: string, type: EPDContentDeclarationProductType) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_PRODUCT_DANGER_MATERIALS, epdVersionId, type],
    queryFn: () => getEpdProductDangerMaterials(epdVersionId, type),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useDangerMaterialsLookup = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.DANGER_MATERIALS_LOOKUP],
    queryFn: () => getDangerMaterialsLookup(),
    refetchOnWindowFocus: false,
  });
};

export const useGetLCASpecification = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_LCA_SPECIFICATION, epdVersionId],
    queryFn: () => getLCASpecification(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useGetInfrastructureAndCapitalGoods = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_INFRASTRUCTURE_AND_CAPITAL_GOODS, epdVersionId],
    queryFn: () => getInfrastructureAndCapitalGoods(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdReferenceFlow = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_REFERENCE_FLOW, epdVersionId],
    queryFn: () => getReferenceFlow(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdConversionFactor = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_CONVERSION_FACTOR, epdVersionId],
    queryFn: () => getConversionFactor(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdDataSources = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_DATA_SOURCES, epdVersionId],
    queryFn: () => getEpdDataSources(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdDataSourceOptions = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_DATA_SOURCE_OPTIONS],
    queryFn: () => getEpdDataSourceOptions(),
    refetchOnWindowFocus: false,
  });
};

export const useReferencePackageVersions15804 = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.REFERENCE_PACKAGE_VERSIONS_15804],
    queryFn: ({ signal }) => getReferencePackageVersions15804({ signal }),
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
};

export const useLCASoftwareOptions = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_LCA_SOFTWARE_OPTIONS],
    queryFn: () => getLCASoftwareOptions(),
    refetchOnWindowFocus: false,
  });
};

export const useGetEpdCO2UptakeAssociation = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_CO2_UPTAKE_ASSOCIATION, epdVersionId],
    queryFn: () => getEpdCO2UptakeAssociation(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useGetEpdDeclarationsOfCO2 = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_DECLARATIONS_OF_CO2, epdVersionId],
    queryFn: () => getEpdDeclarationsOfCO2(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useGetEpdScrapInputs = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_SCRAP_INPUTS, epdVersionId],
    queryFn: () => getEpdScrapInputs(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useGetEpdShareOfTotalScrapInput = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_SHARE_OF_TOTAL_SCRAP_INPUT, epdVersionId],
    queryFn: () => getEpdShareOfTotalScrapInput(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useElectricity = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_ELECTRICITY, epdVersionId],
    queryFn: ({ signal }) => getElectricity(epdVersionId, { signal }),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useGeographicalScopeLookup = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.GEOGRAPHICAL_SCOPE_LOOKUP],
    queryFn: () => getGeographicalScopeLookup(),
    refetchOnWindowFocus: false,
  });
};

export const useMaterialPropertiesLookup = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.MATERIAL_PROPERTIES_LOOKUP],
    queryFn: () => getMaterialPropertiesLookup(),
    refetchOnWindowFocus: false,
  });
};

export const useCountriesLookup = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.FOUNDATION_COUNTRIES],
    queryFn: () => getCountriesLookup(),
    refetchOnWindowFocus: false,
  });
};

export const useEpdScenarios = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_SCENARIOS, epdVersionId],
    queryFn: () => getEpdScenarios(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdScenarioModule = (request: ScenarioModuleRequest) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_MODULE, request.epdVersionId, request.scenarioId, request.module],
    queryFn: () => getEpdScenarioModule(request),
    refetchOnWindowFocus: false,
    enabled: !!request?.scenarioId && !!request?.module,
  });
};

export const useEpdScenarioUnits = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_SCENARIO_UNITS],
    queryFn: () => getEpdScenarioUnits(),
    refetchOnWindowFocus: false,
  });
};

export const useEpdLcaResultsAdditional = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_LCA_RESULTS_ADDITIONAL, epdVersionId],
    queryFn: () => getEpdLcaResultsAdditional(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdLcaResultsAdditionalAcronyms = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_LCA_RESULTS_ADDITIONAL_ACRONYMS, epdVersionId],
    queryFn: ({ signal }) => getEpdLcaResultsAdditionalAcronyms(epdVersionId, { signal }),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdLcaResultsParse = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_LCA_RESULTS, epdVersionId],
    queryFn: () => parseAndSaveLCAFile(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdLcaResults = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_LCA_RESULTS, epdVersionId],
    queryFn: ({ signal }) => getEpdLcaResults(epdVersionId, { signal }),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdLcaCustomResults = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_LCA_CUSTOM_RESULTS, epdVersionId],
    queryFn: () => getEpdLcaCustomResults(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdLcaCustomResultGridData = (customResultId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_LCA_CUSTOM_RESULTS_DATA, customResultId],
    queryFn: () => getEpdLcaCustomResultGridData(customResultId),
    refetchOnWindowFocus: false,
    enabled: !!customResultId,
  });
};

export const useEpdCpcrSpecificData = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_CPCR_SPECIFIC_DATA, epdVersionId],
    queryFn: () => getCpcrSpecificData(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdSystemBoundaryGrid = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_LCA_DECLARED_MODULES, epdVersionId],
    queryFn: () => getEpdSystemBoundaryGrid(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useEpdLcaTransportation = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_LCA_TRANSPORTATION, epdVersionId],
    queryFn: () => getEpdTransportationPerformance(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useCompanyVerifiers = (companyId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_COMPANY_VERIFIERS, companyId],
    queryFn: () => getCompanyVerifiers(companyId),
    refetchOnWindowFocus: false,
  });
};

export const useGetEpdDataQualityAssessment = (epdVersionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_DATA_QUALITY_ASSESSMENT_AND_REFERENCE_YEARS, epdVersionId],
    queryFn: () => getEpdDataQualityAssessmentAndReferenceYears(epdVersionId),
    refetchOnWindowFocus: false,
    enabled: !!epdVersionId,
  });
};

export const useGetEpdQRCode = (friendlyUrl: string, selectedQRCodeType: QRCodeTypeEnum) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_QR_CODE, friendlyUrl],
    queryFn: () => getQRCode(friendlyUrl, selectedQRCodeType),
    refetchOnWindowFocus: false,
    enabled: false,
  });
};

export const useGetEpdQRCodePDF = (friendlyUrl: string | null) => {
  return useQuery({
    queryKey: [QUERY_KEYS.EPD_QR_CODE_FOR_PDF, friendlyUrl],
    queryFn: () => getQRCode(friendlyUrl as string, QRCodeTypeEnum.PrintSizeColoured),
    refetchOnWindowFocus: false,
    enabled: !!friendlyUrl,
  });
};
